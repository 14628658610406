import { Dayjs } from 'dayjs';
import { capitalize } from 'lodash';

export const formatDate = (date: Dayjs | undefined) =>
  date ? capitalize(date.format('dddd, D MMMM YYYY')) : '-';

export const formatTime = (start: Dayjs | undefined, end: Dayjs | undefined) =>
  start && end ? `${start.format('HH:mm')} - ${end.format('HH:mm')}` : '-';

export const getTimeOfDay = (date: Dayjs) => {
  return date.year(0).month(0).date(1);
};
