import dayjs, { Dayjs } from 'dayjs';

import MobileCalendarDate from '../types/MobileCalendarDate';

export const getCourseInstancesBetweenDates = (
  calendarDates: MobileCalendarDate[],
  startDate: Dayjs | null,
  endDate?: Dayjs | null,
) => {
  if (!startDate) {
    return [];
  }

  if (startDate && !endDate) {
    return (
      calendarDates.find((calendarDate) =>
        dayjs(calendarDate.date).isSame(startDate, 'day'),
      )?.courseInstances ?? []
    );
  } else {
    return calendarDates
      .filter((calendarDate) =>
        dayjs(calendarDate.date).isBetween(startDate, endDate, null, '[]'),
      )
      .flatMap((calendarDate) => calendarDate.courseInstances);
  }
};

export const getTimeSlotsBetweenDates = (
  calendarDates: MobileCalendarDate[],
  startDate: Dayjs | null,
  endDate?: Dayjs | null,
) => {
  if (!startDate) {
    return [];
  }

  if (startDate && !endDate) {
    return (
      calendarDates.find((calendarDate) =>
        dayjs(calendarDate.date).isSame(startDate, 'day'),
      )?.timeSlots ?? []
    );
  } else {
    return calendarDates
      .filter((calendarDate) =>
        dayjs(calendarDate.date).isBetween(startDate, endDate, null, '[]'),
      )
      .flatMap((calendarDate) => calendarDate.timeSlots);
  }
};
