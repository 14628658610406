import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import MobileCalendarDate from '../types/MobileCalendarDate';

export type CalendarFilter = {
  availability: boolean;
  matches: boolean;
  potentialCourseInstances: boolean;
};

type Props = {
  filter: CalendarFilter;
  setFilter: React.Dispatch<React.SetStateAction<CalendarFilter>>;
  calendarDates: MobileCalendarDate[];
  className?: string;
};

type ButtonProps = {
  active: boolean;
  text: string;
  onClick: () => void;
};
const FilterButton: React.FC<ButtonProps> = ({ active, text, onClick }) => {
  return (
    <Button
      className={`mx-2 rounded-full border-2 ${
        active && 'bg-blueGrayLight border-safeLifeLight'
      }`}
      onClick={onClick}>
      {text}
    </Button>
  );
};
const CalendarFilterSelector: React.FC<Props> = ({
  filter,
  setFilter,
  calendarDates,
  className,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`overflow-scroll flex flex-row h-16 items-center bg-white ${className}`}>
      <FilterButton
        active={filter.matches}
        text={`${
          calendarDates.flatMap(
            (calendarDate) => calendarDate.matchedCourseInstances,
          ).length
        } ${t('common.matched')}`}
        onClick={() =>
          setFilter((prevState) => {
            const newState = { ...prevState };
            newState.matches = !filter.matches;
            return newState;
          })
        }
      />
      <FilterButton
        active={filter.potentialCourseInstances}
        text={`${
          calendarDates.flatMap(
            (calendarDate) => calendarDate.potentialCourseInstances,
          ).length
        } ${t('components.CalendarFilterSelector.possibleCourses')}`}
        onClick={() =>
          setFilter((prevState) => {
            const newState = { ...prevState };
            newState.potentialCourseInstances =
              !filter.potentialCourseInstances;
            return newState;
          })
        }
      />
    </div>
  );
};

export default CalendarFilterSelector;
