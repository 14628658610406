import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form, Input, Button, Alert, message } from 'antd';
import { useTranslation } from 'react-i18next';

import * as API from '../api';
import Logo from '../resources/img/logo.svg';

type FormValues = {
  password: string;
  confirmPassword: string;
};

const Login: React.FC = () => {
  const [error, setError] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState<string>();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    setToken(searchParams.get('token') || undefined);
  }, [searchParams]);

  const onFinish = async (values: FormValues) => {
    setLoading(true);
    try {
      await API.resetPassword({
        token: token ? token : '',
        password: values.password,
      });
      message.success(t('views.ResetPassword.changedPassword'));
    } finally {
      setLoading(false);

      navigate('/logga-in', { replace: true });
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-200 py-12 px-4 sm:px-6 lg:px-8 w-full">
      <div className="max-w-md w-full">
        <div className="flex flex-col justify-center mb-4">
          <img src={Logo} className="w-20 m-auto" />
          <h2 className="mt-6 text-center text-3xl leading-9 font-bold">
            {t('views.ResetPassword.resetPassword')}
          </h2>
        </div>

        <div className="bg-white p-10 shadow-md rounded-md">
          <Form
            onFinish={(values) => onFinish(values as FormValues)}
            onValuesChange={() => setError(null)}
            layout="vertical">
            <Form.Item
              label={t('views.ResetPassword.newPassword')}
              name="password"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('views.ResetPassword.enterPassword'),
                },
                {
                  min: 8,
                  message: t('views.ResetPassword.minimumPasswordLength'),
                },
              ]}>
              <Input.Password size="large" />
            </Form.Item>
            <Form.Item
              label={t('views.ResetPassword.confirmPassword')}
              name="confirmPassword"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('views.ResetPassword.enterPassword'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t('views.ResetPassword.noMatchedPassword')),
                    );
                  },
                }),
              ]}>
              <Input.Password size="large" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
                size="large"
                className="w-full mt-4 font-medium bg-primary-800 border-primary-800 hover:bg-primary-700 hover:border-primary-700 focus:bg-primary-900 focus:border-primary-900">
                {t('views.ResetPassword.confirm')}
              </Button>
            </Form.Item>
            {error && <Alert message={error} type="error" />}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
