import React, { useState, useContext } from 'react';

interface AppBarInterface {
  title: string;
  showBackButton?: boolean;
  backButtonPath?: string;
}

const AppBarContext = React.createContext<
  [AppBarInterface, React.Dispatch<React.SetStateAction<AppBarInterface>>]
>([{ title: 'Safelife' }, () => {}]);

export const useAppBar = () => useContext(AppBarContext);

type AppBarContextProps = {
  value: AppBarInterface;
};

const AppBarContextProvider = ({
  children,
  value,
}: React.PropsWithChildren<AppBarContextProps>) => {
  const [appBarInterface, setAppBarInterface] =
    useState<AppBarInterface>(value);

  return (
    <AppBarContext.Provider value={[appBarInterface, setAppBarInterface]}>
      {children}
    </AppBarContext.Provider>
  );
};

export default AppBarContextProvider;
