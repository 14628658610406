import React, { useState } from 'react';

import * as API from '../api';
import { useUser } from '../context/user';
import AvailabilityPreset from '../types/AvailabilityPreset';

import SafeLifeSpin from './SafeLifeSpin';
import TimeIntervalPicker, {
  stringifyTime,
  TimeBlock,
  TimeInterval,
} from './TimeIntervalPicker';

type PresetIntervalPickerProps = {
  presets: TimeInterval[];
  setPresets: (intervals: TimeInterval[]) => void;
  loadingPresets?: boolean;
};

const PresetIntervalPicker: React.FC<PresetIntervalPickerProps> = ({
  presets,
  setPresets,
  loadingPresets,
}) => {
  const [currentUser] = useUser();
  const [loadingAddInterval, setLoadingAddInterval] = useState(false);
  const [loadingUpdateInterval, setLoadingUpdateInterval] = useState(false);
  const [loadingDeleteInterval, setLoadingDeleteInterval] = useState<
    number | null
  >(null);

  const createNewInterval = async (timeInterval: Omit<TimeInterval, 'id'>) => {
    if (!currentUser) return;

    setLoadingAddInterval(true);
    try {
      const { data } = await API.createAvailabilityPreset(currentUser.id, {
        startsAt: stringifyTime(timeInterval.start),
        endsAt: stringifyTime(timeInterval.end),
      });
      setPresets([...presets, availabilityPresetToTimeInterval(data)]);
    } finally {
      setLoadingAddInterval(false);
    }
  };

  const updateInterval = async (
    id: number,
    type: 'start' | 'end',
    time: TimeBlock,
  ) => {
    if (!currentUser) return;
    const currentIndex = presets.findIndex((interval) => interval.id === id);
    if (currentIndex === -1) return;
    const currentInterval = presets[currentIndex];

    setLoadingUpdateInterval(true);
    const newEndTime =
      type === 'end'
        ? time
        : stringifyTime(time) < stringifyTime(currentInterval.end)
          ? currentInterval.end
          : time.minute === 0
            ? { ...time }
            : { hour: time.hour + 1, minute: 0 };
    try {
      await API.updateAvailabilityPreset(currentUser.id, id, {
        startsAt: stringifyTime(
          type === 'start' ? time : currentInterval.start,
        ),
        endsAt: stringifyTime(newEndTime),
      });
      setPresets([
        ...presets.slice(0, currentIndex),
        {
          id,
          start: type === 'start' ? time : currentInterval.start,
          end: newEndTime,
        },
        ...presets.slice(currentIndex + 1),
      ]);
    } finally {
      setLoadingUpdateInterval(false);
    }
  };

  const deleteInterval = async (id: number) => {
    if (!currentUser) return;

    setLoadingDeleteInterval(id);
    try {
      await API.deleteAvailabilityPreset(currentUser.id, id);
      setPresets(presets.filter((interval) => interval.id !== id));
    } finally {
      setLoadingDeleteInterval(null);
    }
  };

  return (
    <div className="mt-2 w-full flex justify-center">
      {loadingPresets ? (
        <SafeLifeSpin />
      ) : (
        <TimeIntervalPicker
          selectedIntervals={presets}
          onAddInterval={createNewInterval}
          loadingAdd={loadingAddInterval}
          onUpdateInterval={updateInterval}
          loadingUpdate={loadingUpdateInterval}
          onDeleteInterval={deleteInterval}
          loadingDeleteId={loadingDeleteInterval}
          requireConfirmDelete
          maxIntervals={4}
        />
      )}
    </div>
  );
};

export const availabilityPresetToTimeInterval = ({
  id,
  startsAt,
  endsAt,
}: AvailabilityPreset) => {
  const [startHour, startMinute] = startsAt.split(':').map(Number);
  const [endHour, endMinute] = endsAt.split(':').map(Number);
  return {
    id,
    start: { hour: startHour, minute: startMinute },
    end: { hour: endHour, minute: endMinute },
  };
};

export default PresetIntervalPicker;
