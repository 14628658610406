import Language from '../types/Language';

import client from './client';

export const getLanguages = (name?: number, code?: number) =>
  client.get<Language[]>(`/languages/`, {
    params: {
      name,
      code,
    },
  });

export const getLanguage = (id: Language['id']) =>
  client.get<Language>(`/languages/${id}`);

export const getAttendeeLanguages = (name?: number, code?: number) =>
  client.get<Language[]>(`/languages/attendees/`, {
    params: {
      name,
      code,
    },
  });

export const getAttendeeLanguage = (id: number) =>
  client.get<Language[]>(`/languages/attendees/${id}`);
