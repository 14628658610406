import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { CopyOutlined, QrcodeOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';

import CourseInstance from '../types/CourseInstance';
import * as API from '../api';
import * as DateUtils from '../utils/date';
import CourseInstanceStatusBadge from '../components/CourseInstanceStatusBadge';
import { removeCountryFromAddress } from '../utils/address';
import GoogleMap from '../components/GoogleMap';
import { useAppBar } from '../context/AppBar';
import { useAppContainer } from '../context/AppContainer';
import SafeLifeSpin from '../components/SafeLifeSpin';
import AcceptCourseInstanceModal from '../components/AcceptCourseInstanceModal';
import { courseInstanceIsAcceptable } from '../utils/courseInstance';
import CourseResource from '../types/CourseResource';
import CourseResourcesList from '../components/CourseResourcesList';
import CourseInstanceAttendeesInfoCell from '../components/CourseInstanceAttendeesInfoCell';
import { getFullContactName } from '../utils/contactPerson';
import { QRCodeModal } from '../components/QRCodeModal';

type InfoCellProps = {
  title: string;
  children: React.ReactNode;
};

export const InfoCell: React.FC<InfoCellProps> = ({ title, children }) => {
  return (
    <div className="flex flex-col py-2">
      <div className="text-blueGrayDark text-xs mb-1">{title}</div>
      <div className="break-words">{children}</div>
    </div>
  );
};

type CourseInstanceDetailsParams = {
  id: string;
};

const CourseInstanceDetails = () => {
  const params = useParams<CourseInstanceDetailsParams>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [courseInstance, setCourseInstance] = useState<CourseInstance>();
  const [loadingCourseInstance, setLoadingCourseInstance] = useState(false);
  const [courseResources, setCourseResources] = useState<CourseResource[]>();
  const [loadingCourseResources, setLoadingCourseResources] = useState(false);
  const [isPotentialCourseInstance, setIsPotentialCourseInstance] =
    useState<boolean>(false);
  const [start, setStart] = useState<Dayjs>();
  const [end, setEnd] = useState<Dayjs>();
  const [, setAppBarInterface] = useAppBar();
  const [, setCurrentClasses] = useAppContainer();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showQR, setShowQR] = useState(false);

  const id = Number(params.id);

  useEffect(() => {
    setAppBarInterface({
      title: t('common.courseInstance'),
      showBackButton: true,
    });
  }, [i18next.language]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCurrentClasses('bg-white');
    fetchCourseInstance();
    window.scrollTo(0, 0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (courseInstance) {
      setStart(dayjs(courseInstance.startsAt));
      setEnd(dayjs(courseInstance.endsAt));
      fetchCourseResources(courseInstance.course.id);
    }
    setIsPotentialCourseInstance(courseInstance?.instructor === null);
  }, [courseInstance]);

  const onModalSuccess = () => {
    if (location.state) {
      navigate(-1);
    } else {
      navigate('/market');
    }
  };

  const attendeesQueryKey = ['getCourseInstanceAttendees', courseInstance?.id];
  const attendeesQuery = useQuery({
    queryKey: attendeesQueryKey,
    queryFn: () => {
      if (courseInstance?.id) {
        return API.getCourseInstancesAttendees(courseInstance.id);
      }
    },
    enabled: !!courseInstance?.id,
    retry: false,
  });

  const fetchCourseInstance = async () => {
    setLoadingCourseInstance(true);
    try {
      const { data } = await API.getCourseInstance(id);
      setCourseInstance(data);
    } finally {
      setLoadingCourseInstance(false);
    }
  };

  const fetchCourseResources = async (courseId: number) => {
    setLoadingCourseResources(true);
    try {
      const { data } = await API.getCourseResources(courseId);
      setCourseResources(data);
    } finally {
      setLoadingCourseResources(false);
    }
  };

  const Footer: React.FC = () => {
    return (
      <div className="grid grid-cols-2 justify-center bg-white border-solid border-0 border-t-1 border-blueGrayMedium gap-6 p-4 footer-shadow">
        <Button
          className="flex grow-default font-medium text-base bg-safeLifeMedium border-safeLifeMedium text-white focus:opacity-50 items-center justify-center"
          onClick={() => {
            setShowModal(true);
          }}>
          <div>{t('common.accept')}</div>
        </Button>
        <Button
          className="flex grow-default font-medium text-base items-center justify-center"
          onClick={() => {
            if (location.state) {
              navigate(-1);
            } else {
              navigate('/start');
            }
          }}>
          <div>{t('common.cancel')}</div>
        </Button>
      </div>
    );
  };

  return (
    <div className="py-2 bg-white h-full view-max-width max-w-sm">
      {!loadingCourseInstance ? (
        <>
          <div
            className={`px-3 pt-2 ${
              isPotentialCourseInstance ? 'pb-20' : 'pb-2'
            } `}>
            <div className="flex justify-between align-middle items-center pt-4">
              <h2 className="sm:max-w-xs font-semibold text-2xl break-words">
                {courseInstance?.clientName ?? '-'}
              </h2>
              <h2>
                <CourseInstanceStatusBadge
                  status={courseInstance?.status}
                  courseInstance={courseInstance}
                />
              </h2>
            </div>
            <InfoCell title={t('common.time')}>
              <div className="flex flex-col">
                <span>{DateUtils.formatDate(start)}</span>
                <span>{DateUtils.formatTime(start, end)}</span>
              </div>
            </InfoCell>
            <InfoCell title={t('common.course')}>
              {courseInstance?.course.name ?? '-'}
            </InfoCell>
            <InfoCell
              title={t('views.CourseInstanceDetails.courseInformation')}>
              <CourseResourcesList
                loadingCourseResources={loadingCourseResources}
                courseResources={courseResources}></CourseResourcesList>
            </InfoCell>
            {courseInstance?.elsaEnrollmentShareUrl && (
              <InfoCell title={t('views.CourseInstanceDetails.enrollment')}>
                <div className="flex flex-col gap-2">
                  <a
                    href={courseInstance.elsaEnrollmentShareUrl}
                    rel="noreferrer"
                    target="_blank">
                    {courseInstance.elsaEnrollmentShareUrl}
                  </a>
                  <div className="flex flex-row items-center gap-2">
                    <Button
                      icon={<QrcodeOutlined />}
                      onClick={() => setShowQR(true)}>
                      {t('views.CourseInstanceDetails.showQR')}
                    </Button>
                    <Button
                      icon={<CopyOutlined />}
                      onClick={() => {
                        if (courseInstance.elsaEnrollmentShareUrl) {
                          void navigator.clipboard.writeText(
                            courseInstance.elsaEnrollmentShareUrl,
                          );
                        }
                      }}>
                      {t('views.CourseInstanceDetails.copyLink')}
                    </Button>
                  </div>
                </div>
              </InfoCell>
            )}
            {courseInstance && (
              <CourseInstanceAttendeesInfoCell
                id={id}
                attendeesQuery={attendeesQuery}
                endsAt={courseInstance.endsAt}
              />
            )}
            <InfoCell title={t('common.address')}>
              {courseInstance && (
                <>
                  <div className="pb-3">
                    {removeCountryFromAddress(courseInstance.address) ?? '-'}
                  </div>
                  {courseInstance.lng && courseInstance.lat && (
                    <GoogleMap
                      pinLocation={{
                        lng: courseInstance.lng,
                        lat: courseInstance.lat,
                      }}
                      className="w-full rounded-lg max-w-sm"
                      width={335}
                      height={230}
                    />
                  )}
                </>
              )}
            </InfoCell>
            <InfoCell title={t('common.comment')}>
              <div style={{ whiteSpace: 'pre-line' }}>
                {courseInstance?.comment ?? '-'}
              </div>
            </InfoCell>
            <InfoCell title={t('common.contactPerson')}>
              {
                <>
                  <div>
                    {courseInstance?.contactPerson
                      ? getFullContactName(courseInstance?.contactPerson)
                      : '-'}
                  </div>
                  <div>
                    {courseInstance?.contactPerson?.phoneNumber ? (
                      <a
                        href={`tel:${courseInstance?.contactPerson.phoneNumber}`}>
                        {courseInstance?.contactPerson.phoneNumber}
                      </a>
                    ) : (
                      '-'
                    )}
                  </div>
                </>
              }
            </InfoCell>
            <InfoCell title={t('views.CourseInstanceDetails.equipment')}>
              {courseInstance?.equipment ?? '-'}
            </InfoCell>
            <InfoCell
              title={t('views.CourseInstanceDetails.responsibleCompany')}>
              {courseInstance?.subsidiaryCompanyName ?? '-'}
            </InfoCell>
            <InfoCell title={t('views.CourseInstanceDetails.createdBy')}>
              <div>{courseInstance?.createdBy?.name ?? '-'}</div>
              <div>{courseInstance?.createdBy?.email ?? '-'}</div>
            </InfoCell>
          </div>
          {courseInstance &&
            isPotentialCourseInstance &&
            courseInstanceIsAcceptable(
              courseInstance.instructor?.id ?? null,
              courseInstance.startsAt,
            ) && (
              <div className="fixed left-0 bottom-0 w-full">
                <Footer />
              </div>
            )}
        </>
      ) : (
        <div className="flex justify-center items-center h-5/6">
          <SafeLifeSpin size="large" />
        </div>
      )}
      {courseInstance && (
        <AcceptCourseInstanceModal
          courseInstanceId={courseInstance.id}
          startsAt={courseInstance.startsAt}
          endsAt={courseInstance.endsAt}
          clientName={courseInstance.clientName}
          address={courseInstance.address}
          open={showModal}
          onCancel={() => setShowModal(false)}
          onSuccess={onModalSuccess}
        />
      )}

      {showQR && courseInstance?.elsaEnrollmentShareUrl && (
        <QRCodeModal
          title={courseInstance.course.name}
          url={courseInstance?.elsaEnrollmentShareUrl}
          close={() => setShowQR(false)}
        />
      )}
    </div>
  );
};

export default CourseInstanceDetails;
