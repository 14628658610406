import React, { useEffect, useState } from 'react';

import Plus from '../resources/img/plus_icon.svg';
import { CourseInstanceStatus } from '../types/CourseInstance';
import MobileCalendarDate from '../types/MobileCalendarDate';

import { CalendarFilter } from './CalendarFilterSelector';

type Props = {
  filter?: CalendarFilter;
  calendarDate?: MobileCalendarDate;
};

const CalendarCourseInstanceDots: React.FC<Props> = ({
  calendarDate,
  filter,
}) => {
  const [confirmedCount, setConfirmedCount] = useState(0);
  const [preliminaryCount, setPreliminaryCount] = useState(0);
  const [dotStack, setDotStack] = useState<string[]>([]);

  useEffect(() => {
    if (!calendarDate) {
      return;
    }

    setConfirmedCount(
      calendarDate.courseInstances.filter(
        (courseInstance) =>
          courseInstance.status === CourseInstanceStatus.Confirmed,
      ).length,
    );

    setPreliminaryCount(
      calendarDate.courseInstances.filter(
        (courseInstance) =>
          courseInstance.status === CourseInstanceStatus.Preliminary,
      ).length,
    );
  }, [calendarDate]);

  useEffect(() => {
    if (!calendarDate) {
      return;
    }

    const newDotStack = [];

    if (newDotStack.length < 3) {
      for (let i = 0; i < confirmedCount; i++) {
        newDotStack.push('confirmed-dot border-white border-solid');
      }
    }

    if (newDotStack.length < 3) {
      for (let i = 0; i < preliminaryCount; i++) {
        newDotStack.push('preliminary-dot border-white border-solid');
      }
    }

    if (filter?.matches && newDotStack.length < 3) {
      calendarDate.matchedCourseInstances.forEach(() => {
        newDotStack.push('matched-dot');
      });
    }

    if (filter?.potentialCourseInstances && newDotStack.length < 3) {
      calendarDate.potentialCourseInstances.forEach(() => {
        newDotStack.push('potential-dot');
      });
    }

    setDotStack(newDotStack);
  }, [confirmedCount, preliminaryCount, calendarDate, filter]);

  return (
    <>
      {dotStack.length > 0 && (
        <>
          {dotStack.length === 1 ? (
            <div className="mr-3 -mt-0.5">
              <div
                className={`${dotStack[0]} w-3 h-3 rounded-default absolute border-1`}
              />
            </div>
          ) : (
            <div className="mr-4 -mt-1">
              <div
                className={`${dotStack[1]} w-3 h-3 rounded-default absolute border-1 ml-1 mt-1`}
              />
              <div
                className={`${dotStack[0]} w-3 h-3 rounded-default absolute border-1`}
              />
              {calendarDate && dotStack.length > 2 && (
                <img
                  src={Plus}
                  className="absolute text-black"
                  style={{
                    marginLeft: '0.8rem',
                    marginTop: '-0.1rem',
                  }}
                />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CalendarCourseInstanceDots;
