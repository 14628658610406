import React, { useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Alert, Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import * as API from '../api';

type ResetPasswordModalProps = {
  visible: boolean;
  onCancel?: () => void;
};

type ResetPasswordFormValues = {
  email: string;
};

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
  visible,
  onCancel,
}) => {
  const [resetPasswordForm] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);

  const onFinish = async (values: ResetPasswordFormValues) => {
    setLoading(true);
    try {
      await API.resetPasswordRequest({
        email: values.email,
      });
    } finally {
      setLoading(false);
    }
  };
  const { t } = useTranslation();
  return (
    <Modal
      title={t('components.ResetPasswordModal.resetPassword')}
      open={visible}
      onCancel={onCancel}
      footer={null}
      width={450}
      centered={true}>
      <Form
        form={resetPasswordForm}
        onFinish={(values) => onFinish(values as ResetPasswordFormValues)}
        layout="vertical">
        <Form.Item label={t('common.email')} name="email">
          <Input type="email" size="large" autoComplete="email" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
            onClick={() => setSent(true)}
            size="large"
            className="w-full mt-4 font-medium bg-primary-800 border-primary-800 hover:bg-primary-700 hover:border-primary-700 focus:bg-primary-900 focus:border-primary-900">
            {t('components.ResetPasswordModal.resetPassword')}
          </Button>
        </Form.Item>
      </Form>
      {!loading && sent && (
        <Alert
          message={t('views.ResetPassword.mailSentToAddress')}
          type="info"
        />
      )}
    </Modal>
  );
};

export default ResetPasswordModal;
