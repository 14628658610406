import React, { useState, useContext } from 'react';

const AppContainerContext = React.createContext<
  [string, (classes: string) => void]
>(['', () => {}]);

export const useAppContainer = () => useContext(AppContainerContext);

type AppBarContextProps = {
  children: React.ReactChild;
  currentClasses: string;
};

const AppContainerProvider: React.FC<AppBarContextProps> = ({
  children,
  currentClasses,
}) => {
  const [classes, setClasses] = useState(currentClasses);

  return (
    <AppContainerContext.Provider value={[classes, setClasses]}>
      {children}
    </AppContainerContext.Provider>
  );
};

export default AppContainerProvider;
