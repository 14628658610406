import { LinkOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CourseResource from '../types/CourseResource';

import SafeLifeSpin from './SafeLifeSpin';

type CourseResourcesListProps = {
  loadingCourseResources: boolean;
  courseResources?: CourseResource[];
};

type CourseResourceProps = {
  url: string;
  title: string;
};

const CourseResourcesList: React.FC<CourseResourcesListProps> = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      {props.loadingCourseResources ? (
        <div className="flex justify-center items-center mt-14">
          <SafeLifeSpin size="small" />
        </div>
      ) : props.courseResources?.length == 0 ? (
        <div className="text-blueGrayMediumDark font-normal text-sm py-4 rounded-lg bg-white flex-1 flex flex-col items-center shadow-md border-solid border-1 border-grayLight">
          {t('common.noCourseInfoAvailable')}
        </div>
      ) : (
        <div>
          {props.courseResources?.map((courseResource, key) => {
            return (
              <CourseResourceButton
                key={key}
                url={courseResource.url}
                title={courseResource.title}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const CourseResourceButton: React.FC<CourseResourceProps> = (props) => {
  return (
    <Button
      className={
        'flex grow-default justify-start items-center font-semibold my-1 py-0 h-9'
      }
      href={props.url}
      target="_blank"
      rel="noreferrer">
      <div className="flex items-center w-full">
        <div className="text-xs truncate overflow-hidden">{props.title}</div>
        <LinkOutlined className="pl-1 text-gray-500" />
      </div>
    </Button>
  );
};

export default CourseResourcesList;
