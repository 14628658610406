import React, { useState } from 'react';
import { Button } from 'antd';
import { range } from 'lodash';
import { Picker } from 'antd-mobile';
import { PickerColumn, PickerValue } from 'antd-mobile/es/components/picker';
import { DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { formatCourseInstancesString } from '../utils/courseInstance';

type CourseInstanceLimitPickerProps = {
  maxCourseInstanceLimit?: number;
  courseInstanceLimit?: number;
  className?: string;
  setCourseInstanceLimit: (value: number) => void;
};

const CourseInstanceLimitPicker: React.FC<CourseInstanceLimitPickerProps> = ({
  maxCourseInstanceLimit,
  courseInstanceLimit,
  className,
  setCourseInstanceLimit,
}) => {
  const defaultCourseInstanceLimit = 2;
  const defaultMaxCourseInstanceLimit = 4;
  const [editing, setEditing] = useState(false);
  const { t } = useTranslation();

  const pickerColumns: PickerColumn[] = [
    range(1, (maxCourseInstanceLimit ?? defaultMaxCourseInstanceLimit) + 1).map(
      (value) => `${value}`,
    ),
  ];

  const onConfirm = (values: PickerValue[]) => {
    if (!editing || !values[0]) {
      return;
    }
    try {
      const newLimit = parseInt(String(values[0]));
      setCourseInstanceLimit(newLimit);
    } finally {
      setEditing(false);
    }
  };

  return (
    <div className={`${className}`}>
      <Button
        className={`w-full font-semibold h-10`}
        onClick={() => setEditing(true)}>
        <div className="flex w-full grow-default items-center align-center justify-between text-sm	font-medium">
          {`Max ${
            courseInstanceLimit ?? defaultCourseInstanceLimit
          } ${formatCourseInstancesString(
            courseInstanceLimit ?? defaultCourseInstanceLimit,
            t,
          )}`}
          <DownOutlined />
        </div>
      </Button>
      <Picker
        columns={pickerColumns}
        confirmText={t('common.choose')}
        cancelText={t('common.cancel')}
        visible={editing}
        value={[`${courseInstanceLimit}`]}
        title={t(
          'components.CourseInstanceLimitPicker.chooseMaxCourseInstances',
        )}
        onConfirm={(value) => onConfirm(value)}
        onCancel={() => setEditing(false)}
      />
    </div>
  );
};

export default CourseInstanceLimitPicker;
