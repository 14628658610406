import AvailabilityInfo from '../types/AvailabilityInfo';
import AvailabilityPreset from '../types/AvailabilityPreset';
import CourseInstanceLimit from '../types/CourseInstanceLimit';
import { ImageMedia } from '../types/ImageMedia';
import TimeSlot from '../types/TimeSlot';
import User, { UserRole } from '../types/User';

import client from './client';

export type UserFilterParams = {
  userIds?: User['id'][];
  roles?: UserRole[];
  deleted?: boolean;
};

//Dates in format of YYYY-MM-DD
export type GetCourseInstanceLimitsParams = {
  startDate: string;
  endDate: string;
};

export type GetAvailabilityPrams = {
  startDate: string;
  endDate: string;
};

export const getProfile = () => client.get<User>('/users/profile');

type BatchAddAvailabilityParams = {
  timeIntervals: { start: string; end: string }[];
  courseInstanceLimit: number;
  startDate: string;
  endDate: string;
  timeZone: string;
  weekdays?: number[];
};

export const batchAddAvailability = (
  userId: User['id'],
  params: BatchAddAvailabilityParams,
) => client.post<TimeSlot>(`/users/${userId}/slots/batch`, params);

type IcsToken = {
  icsToken: string;
};

export const getUserCalendarLink = (userId: User['id']) =>
  client.get<IcsToken>(`/users/${userId}/calendar`);

export const getUserAvailabilityPresets = (userId: User['id']) =>
  client.get<AvailabilityPreset[]>(`/users/${userId}/availability-presets`);

type CreateAvailabilityPresetParams = {
  startsAt: string;
  endsAt: string;
};

export const createAvailabilityPreset = (
  userId: User['id'],
  params: CreateAvailabilityPresetParams,
) =>
  client.post<AvailabilityPreset>(
    `/users/${userId}/availability-presets`,
    params,
  );

export const updateAvailabilityPreset = (
  userId: User['id'],
  availabilityPresetId: AvailabilityPreset['id'],
  params: CreateAvailabilityPresetParams,
) =>
  client.patch<AvailabilityPreset[]>(
    `/users/${userId}/availability-presets/${availabilityPresetId}`,
    params,
  );

export const deleteAvailabilityPreset = (
  userId: User['id'],
  availabilityPresetId: AvailabilityPreset['id'],
) =>
  client.delete(
    `/users/${userId}/availability-presets/${availabilityPresetId}`,
  );

export const getAvailability = (
  userId: User['id'],
  params: GetAvailabilityPrams,
) =>
  client.get<AvailabilityInfo[]>(`/users/${userId}/availability`, {
    params: { ...params },
  });

export const getUserCourseInstanceLimits = (
  userId: User['id'],
  courseInstanceLimitParams: GetCourseInstanceLimitsParams,
) =>
  client.get<CourseInstanceLimit[]>(`/users/${userId}/course-instance-limits`, {
    params: { ...courseInstanceLimitParams },
  });

type UpdateDefaultCourseInstanceLimitParams = {
  defaultCourseInstanceLimit: number;
};

export const updateDefaultCourseInstanceLimit = (
  id: User['id'],
  params: UpdateDefaultCourseInstanceLimitParams,
) => client.patch<User>(`/users/${id}/default-course-instance-limit`, params);

type UpdatePreferredLanguageParams = {
  preferredLanguageId: number | null;
};

export const updatePreferredLanguage = (
  id: User['id'],
  params: UpdatePreferredLanguageParams,
) => client.patch<User>(`/users/${id}/preferred-language`, params);

export const putUserCertificateSignature = (id: number, data: FormData) =>
  client.put<ImageMedia>(`/users/${id}/certificate-signature`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const deleteUserCertificateSignature = (id: number) =>
  client.delete(`/users/${id}/certificate-signature`);
