import React from 'react';
import {
  ClockCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import CourseInstance, { CourseInstanceStatus } from '../types/CourseInstance';

const CourseInstanceStatusBadge: React.FC<{
  status?: CourseInstanceStatus;
  courseInstance?: CourseInstance;
}> = ({ status, courseInstance }) => {
  const courseInstanceStatus = status ?? courseInstance?.status;
  const { t } = useTranslation();

  const statusText: { [key in CourseInstanceStatus]: string } = {
    [CourseInstanceStatus.Canceled]: t(
      'components.CourseInstanceStatusBadge.canceled',
    ),
    [CourseInstanceStatus.Preliminary]: t(
      'components.CourseInstanceStatusBadge.preliminary',
    ),
    [CourseInstanceStatus.Confirmed]: t(
      'components.CourseInstanceStatusBadge.confirmed',
    ),
  };

  const statusStyle: { [key in CourseInstanceStatus]: string } = {
    [CourseInstanceStatus.Canceled]: 'bg-red-100 text-red-800',
    [CourseInstanceStatus.Preliminary]: 'bg-yellow-100 text-yellow-800',
    [CourseInstanceStatus.Confirmed]: 'bg-successLight text-success',
  };

  const statusIcon: { [key in CourseInstanceStatus]: React.ReactNode } = {
    [CourseInstanceStatus.Canceled]: (
      <CloseOutlined className="text-red-800 pr-1" />
    ),
    [CourseInstanceStatus.Preliminary]: (
      <ClockCircleOutlined className="text-yellow-800 pr-1" />
    ),
    [CourseInstanceStatus.Confirmed]: (
      <CheckOutlined className="text-success pr-1" />
    ),
  };

  return (
    <>
      {courseInstanceStatus ? (
        <div
          className={`h-6 px-2 inline-flex text-xs font-semibold rounded-full ${statusStyle[courseInstanceStatus]}`}>
          <div className="flex items-center">
            {courseInstance &&
            courseInstanceStatus === CourseInstanceStatus.Confirmed &&
            dayjs(courseInstance.endsAt).isBefore(dayjs()) ? (
              t('common.completed')
            ) : (
              <>
                {statusIcon[courseInstanceStatus]}
                {statusText[courseInstanceStatus]}
              </>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CourseInstanceStatusBadge;
