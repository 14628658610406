import React, { useState, useContext } from 'react';

import User from '../types/User';

const UserContext = React.createContext<
  [User | null, (user: User | null) => void]
>([null, () => {}]);

export const useUser = () => useContext(UserContext);

type UserContextProviderProps = {
  children: React.ReactChild;
  value: User | null;
};

const UserContextProvider: React.FC<UserContextProviderProps> = ({
  children,
  value,
}) => {
  const [user, setUser] = useState<User | null>(value);

  return (
    <UserContext.Provider value={[user, setUser]}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
