import React, { useState, useContext, useEffect } from 'react';

import { setAuthorizationToken } from '../api/client';

export type Token = string | null | undefined;

const TokenContext = React.createContext<[Token, (token: Token) => void]>([
  undefined,
  () => {},
]);

export const useToken = () => useContext(TokenContext);

type TokenContextProviderProps = {
  children: React.ReactChild;
};

const TokenContextProvider: React.FC<TokenContextProviderProps> = ({
  children,
}) => {
  const [token, setToken] = useState<Token>(undefined);

  useEffect(() => {
    storeToken(localStorage.getItem('token') || null);
  }, []);

  const storeToken = (jwt: Token) => {
    setAuthorizationToken(jwt);
    setToken(jwt);
    if (jwt) {
      localStorage.setItem('token', jwt);
    } else {
      localStorage.removeItem('token');
    }
  };

  return (
    <TokenContext.Provider value={[token, storeToken]}>
      {children}
    </TokenContext.Provider>
  );
};

export default TokenContextProvider;
