import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Form, Input, Button, Alert } from 'antd';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import { useToken } from '../context/token';
import { useUser } from '../context/user';
import * as API from '../api';
import ResetPasswordModal from '../components/ResetPasswordModal';
import Logo from '../resources/img/logo.svg';

type FormValues = {
  email: string;
  password: string;
};

const Login: React.FC = () => {
  const location = useLocation();
  const [, setToken] = useToken();
  const [, setUser] = useUser();
  const [error, setError] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const [showResetPasswordModal, setShowResetPasswordModal] =
    useState<boolean>(false);

  const onFinish = async (values: FormValues) => {
    setLoading(true);
    try {
      const { data } = await API.login({
        email: values.email,
        password: values.password,
      });
      setUser(data.user);
      setToken(data.jwt);
    } catch (error) {
      if (isAxiosError(error)) {
        setError(error?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-200 py-12 px-4 sm:px-6 lg:px-8 h-full w-full">
      <div className="max-w-md w-full">
        <div className="flex flex-col justify-center mb-4">
          <img src={Logo} className="w-20 m-auto" />
          <h2 className="mt-6 text-center text-3xl leading-9 font-bold">
            {t('views.Login.signIn')}
          </h2>
        </div>

        {location.state?.sessionExpired && (
          <Alert
            type="error"
            message={t('views.Login.sessionExit')}
            description={t('views.Login.signInAgain')}
            showIcon
            className="mb-4"
          />
        )}

        <div className="bg-white p-10 shadow-md rounded-md">
          <Form
            onFinish={(values) => onFinish(values as FormValues)}
            onValuesChange={() => setError(null)}
            layout="vertical">
            <Form.Item label={t('common.email')} name="email">
              <Input type="email" size="large" autoComplete="email" />
            </Form.Item>
            <Form.Item label={t('common.password')} name="password">
              <Input type="password" size="large" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
                size="large"
                className="w-full mt-4 font-medium bg-primary-800 border-primary-800 hover:bg-primary-700 hover:border-primary-700 focus:bg-primary-900 focus:border-primary-900">
                {t('common.signIn')}
              </Button>
            </Form.Item>
            <a onClick={() => setShowResetPasswordModal(true)}>
              {t('common.forgetPassword')}
            </a>
            {error && (
              <div className="pt-3">
                <Alert message={error} type="error" />
              </div>
            )}
            <ResetPasswordModal
              visible={showResetPasswordModal}
              onCancel={() => setShowResetPasswordModal(false)}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
