import React, { ReactNode } from 'react';

type Props = {
  onClick: () => void;
  icon: ReactNode;
  text: string;
};

const ShortcutButton: React.FC<Props> = (props) => {
  return (
    <button
      onClick={props.onClick}
      className="cursor-pointer rounded-lg bg-white flex-1 flex flex-col items-center shadow-md border-solid border-1 border-grayLight">
      <div className="py-3">
        <span className="block m-1">{props.icon}</span>
        <span className="m-1 font-semibold">{props.text}</span>
      </div>
    </button>
  );
};

export default ShortcutButton;
