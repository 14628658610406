import React from 'react';

import { useAppContainer } from '../context/AppContainer';

type AppContainerProps = {
  children: React.ReactNode;
};

const AppContainer: React.FC<AppContainerProps> = ({ children }) => {
  const [classes] = useAppContainer();

  return (
    <div
      className={`h-full ${classes} flex flex-col items-center overflow-auto`}>
      {children}
    </div>
  );
};

export default AppContainer;
