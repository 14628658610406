import React, { useEffect, useState } from 'react';
import generateCalendar from 'antd/es/calendar/generateCalendar';
import dayjs, { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';

import SafeLifeSpin from '../components/SafeLifeSpin';
import { useCalendar } from '../context/Calendar';
import MobileCalendarDate from '../types/MobileCalendarDate';
import { getCourseInstancesBetweenDates } from '../utils/instructorCalendar';
import { useUser } from '../context/user';
import { getAntdLocale } from '../utils/language';

import { CalendarFilter } from './CalendarFilterSelector';
import CalendarCourseInstanceDots from './CalendarCourseInstanceDots';

const Calendar = generateCalendar<Dayjs>(dayjsGenerateConfig);

type MobileCalendarProps = {
  filter?: CalendarFilter;
  calendarDates: MobileCalendarDate[];
  loading: boolean;
  getCalendarHeader: () => JSX.Element;
};

const MobileCalendar: React.FC<MobileCalendarProps> = ({
  filter,
  calendarDates,
  loading,
  getCalendarHeader,
}) => {
  const [calendarState, setCalendarState] = useCalendar();
  const [antdLocale, setAntdLocal] = useState<PickerLocale>();
  const [currentUser] = useUser();

  useEffect(() => {
    if (currentUser) {
      const userLocale = getAntdLocale(currentUser.preferredLanguage?.code);
      setAntdLocal(userLocale);
    }
  }, [currentUser]);

  const dayHasTimeSlot = (date: Dayjs) => {
    const calendarDate = calendarDates.find((calendarDate) =>
      dayjs(calendarDate.date).isSame(date, 'date'),
    );

    if (calendarDate) {
      return calendarDate.timeSlots.length > 0;
    }
  };

  const getDateCellClasses = (date: Dayjs) => {
    if (!filter?.availability) {
      return '';
    }

    return (
      (dayHasTimeSlot(date) ? 'bg-blueGrayMedium font-medium ' : '') +
      (!dayHasTimeSlot(date.subtract(1, 'day'))
        ? 'rounded-l-md ml-2 pr-2 '
        : '') +
      (!dayHasTimeSlot(date.add(1, 'day')) ? 'rounded-r-md mr-2 pl-2 ' : '')
    );
  };

  const getSelectionClasses = (date: Dayjs) => {
    if (calendarState.selectedDates.start === null) {
      return;
    }

    return (
      (date.isSame(calendarState.selectedDates.start, 'day')
        ? 'rounded-l-md border-l-2 -ml-0.5 '
        : '') +
      (date.isSame(calendarState.selectedDates.end, 'day')
        ? 'rounded-r-md border-r-2 -mr-0.5 '
        : '') +
      (date.isBetween(
        calendarState.selectedDates.start,
        calendarState.selectedDates.end,
        null,
        '[]',
      )
        ? 'border-solid border-blueGrayMedium border-0 border-b-2 border-t-2 bg-gray-100 '
        : '')
    );
  };

  const changeDisplayedMonth = (
    amount: number,
    _start?: Dayjs,
    _end?: Dayjs,
  ) => {
    setCalendarState((prevState) => {
      return {
        displayedMonth: prevState?.displayedMonth.add(amount, 'M'),
        selectedDates: { start: _start ?? null, end: _end ?? null },
        selectedCourseInstancesCount: 0,
      };
    });
  };

  const onDateSelect = (date: Dayjs) => {
    if (
      calendarState.selectedDates.start !== null &&
      calendarState.selectedDates.start.isSame(date, 'day')
    ) {
      setCalendarState((prevState) => ({
        ...prevState,
        selectedDates: { start: null, end: null },
        selectedCourseInstancesCount: 0,
      }));
    }
    if (
      calendarState.selectedDates.start === null ||
      !calendarState.selectedDates.start.isSame(
        calendarState.selectedDates.end,
        'day',
      )
    ) {
      setCalendarState((prevState) => ({
        ...prevState,
        selectedDates: { start: date.startOf('day'), end: date.endOf('day') },
        selectedCourseInstancesCount: getCourseInstancesBetweenDates(
          calendarDates,
          date.startOf('day'),
          date.endOf('day'),
        ).length,
      }));
    } else {
      if (date < calendarState.selectedDates.start) {
        setCalendarState((prevState) => ({
          ...prevState,
          selectedDates: {
            start: date.startOf('day'),
            end: prevState.selectedDates.end,
          },
          selectedCourseInstancesCount: getCourseInstancesBetweenDates(
            calendarDates,
            date.startOf('day'),
            prevState.selectedDates.end,
          ).length,
        }));
      } else {
        setCalendarState((prevState) => ({
          ...prevState,
          selectedDates: {
            start: prevState.selectedDates.start,
            end: date.endOf('day'),
          },
          selectedCourseInstancesCount: getCourseInstancesBetweenDates(
            calendarDates,
            prevState.selectedDates.start,
            date.endOf('day'),
          ).length,
        }));
      }
    }
  };

  const getRedDot = (date: Dayjs) => {
    return;
    const currentDate = calendarDates.find((calendarDate) =>
      dayjs(calendarDate.date).isSame(date, 'day'),
    );
    if (currentDate) {
      return (
        <div className="bg-red-400 w-2 h-2 rounded-lg absolute -mt-8 mr-2" />
      );
    }
  };

  const getDateCell = (date: Dayjs) => {
    return (
      <>
        <div
          className={`flex flex-col h-full ${getSelectionClasses(date)} ${
            loading && 'opacity-25'
          }`}>
          <div
            className={
              calendarState.selectedDates.start &&
              date.isBetween(
                calendarState.selectedDates.start,
                calendarState.selectedDates.end,
                null,
                '[]',
              )
                ? 'mt-2.5'
                : 'mt-3'
            }>
            <div className={`mb-3 ${getDateCellClasses(date)}`}>
              {date.date()}
            </div>
            <div className="flex grow-default justify-center">
              {!loading && (
                <CalendarCourseInstanceDots
                  filter={filter}
                  calendarDate={calendarDates.find((calendarDate) =>
                    dayjs(calendarDate.date).isSame(date, 'day'),
                  )}
                />
              )}
            </div>
            <div className="flex justify-end -mt-1">{getRedDot(date)}</div>
          </div>
        </div>
        <div className="border-solid border-gray-100 border-0 border-b-2" />
      </>
    );
  };

  const onCalendarChange = (newDate: Dayjs) => {
    if (!newDate.isSame(calendarState.displayedMonth, 'month')) {
      changeDisplayedMonth(
        newDate
          .startOf('month')
          .diff(calendarState.displayedMonth.startOf('month'), 'month'),
        newDate.startOf('day'),
        newDate.endOf('day'),
      );
    }
  };

  return (
    <div>
      <div className="flex flex-col items-center bg-blueGrayLight">
        {loading && (
          <div className="w-full flex justify-center  mt-64 absolute z-10">
            <SafeLifeSpin size="large" />
          </div>
        )}
        <Calendar
          headerRender={() => getCalendarHeader()}
          fullCellRender={(date: Dayjs) => getDateCell(date)}
          locale={antdLocale}
          mode={'month'}
          value={calendarState.displayedMonth}
          fullscreen={false}
          onSelect={(date: Dayjs) => onDateSelect(date)}
          onChange={(date: Dayjs) => onCalendarChange(date)}
        />
      </div>
    </div>
  );
};

export default MobileCalendar;
