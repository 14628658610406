import axios, { AxiosError } from 'axios';
import queryString from 'query-string';

import { Token } from '../context/token';

axios.defaults.paramsSerializer = (params) => queryString.stringify(params);

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

let tokenInterceptor: number;

export const setAuthorizationToken = (token: Token) => {
  const authToken = token ? `Bearer ${token}` : '';

  if (tokenInterceptor != null) {
    client.interceptors.request.eject(tokenInterceptor);
  }

  tokenInterceptor = client.interceptors.request.use((config) => {
    if (config.headers) {
      config.headers.Authorization = authToken;
    }
    return config;
  });
};

let errorInterceptor: number;

export const addErrorInterceptor = (
  callback: (error: AxiosError['response']) => void,
) => {
  removeErrorInterceptor();

  errorInterceptor = client.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      callback(error.response);
      throw error;
    },
  );
};

export const removeErrorInterceptor = () => {
  if (errorInterceptor != null) {
    client.interceptors.response.eject(errorInterceptor);
  }
};

export default client;
