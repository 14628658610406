import dayjs from 'dayjs';
import React, {
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';

import { CalendarState } from '../types/Calendar';

import { useLanguage } from './language';

const CalendarContext = React.createContext<
  [CalendarState, Dispatch<SetStateAction<CalendarState>>]
>([
  {
    displayedMonth: dayjs(),
    selectedDates: { start: dayjs().startOf('day'), end: dayjs().endOf('day') },
    selectedCourseInstancesCount: 0,
  },
  () => {},
]);

export const useCalendar = () => useContext(CalendarContext);

type CalendarContextProps = {
  children: React.ReactChild;
  currentState: CalendarState;
};

const CalendarContextProvider: React.FC<CalendarContextProps> = ({
  children,
  currentState,
}) => {
  const [state, setState] = useState(currentState);

  const language = useLanguage()[0];

  useEffect(() => {
    if (language) {
      setState({
        displayedMonth: dayjs().locale(language.code),
        selectedDates: {
          start: dayjs().locale(language.code).startOf('day'),
          end: dayjs().locale(language.code).endOf('day'),
        },
        selectedCourseInstancesCount: 0,
      });
    }
  }, [language]);

  return (
    <CalendarContext.Provider value={[state, setState]}>
      {children}
    </CalendarContext.Provider>
  );
};

export default CalendarContextProvider;
