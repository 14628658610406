import React, { useState } from 'react';
import { Drawer } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
  CalendarOutlined,
  CloseOutlined,
  HomeOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';

import Logo from '../resources/img/logo_white_bg.svg';
import { useAppBar } from '../context/AppBar';
import { useToken } from '../context/token';
import Menu from '../resources/img/menu.svg';
import Back from '../resources/img/back.svg';
import colors from '../theme/colors';

const drawerBodyStyle: React.CSSProperties = {
  backgroundColor: colors.safeLifeMedium,
  borderRadius: '0px',
  display: 'flex',
  flexDirection: 'column',
  color: 'white',
};

const drawerHeaderStyle: React.CSSProperties = {
  backgroundColor: colors.safeLifeDark,
  border: '0px',
  borderRadius: '0px',
  color: 'white',
  height: '4rem',
  display: 'flex',
  alignItems: 'center',
};

const MenuButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <div
      className="w-full h-full flex items-center justify-center"
      onClick={() => onClick()}>
      <img src={Menu} className="p-3 cursor-pointer" />
    </div>
  );
};

const BackButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <div
      className="w-full h-full flex items-center justify-center"
      onClick={() => onClick()}>
      <img src={Back} className="p-3 cursor-pointer" />
    </div>
  );
};

type Nav = {
  path: string;
  text: string;
};

type Props = {
  nav: Nav[];
};

type DrawerRowProps = {
  nav: Nav;
  onClick: (navPath: string) => void;
};

const DrawerRow: React.FC<DrawerRowProps> = ({ nav, onClick }) => {
  const getIcon = (path: string) => {
    switch (path) {
      case '/start':
        return <HomeOutlined />;
      case '/profil':
        return <UserOutlined />;
      case '/kalender':
        return <CalendarOutlined />;
    }
  };

  return (
    <div onClick={() => onClick(nav.path)} className=" pb-3">
      <span className="text-xl pr-2">{getIcon(nav.path)}</span>
      <span className="text-md font-medium">{nav.text}</span>
    </div>
  );
};

const AppBar: React.FC<Props> = (props) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [appBarInterface] = useAppBar();
  const [, setToken] = useToken();

  const onBackButtonClick = () => {
    if (location.state) {
      navigate(-1);
      return;
    }

    if (appBarInterface.backButtonPath) {
      navigate(appBarInterface.backButtonPath);
    } else {
      navigate('/start');
    }
  };

  const onDrawerRowClick = (navPath: string) => {
    setDrawerVisible(false);
    navigate(navPath);
  };

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="w-full bg-safeLifeDark h-16 items-center flex justify-between px-2 py-1.5">
        <div className="w-12">
          {appBarInterface.showBackButton ? (
            <BackButton onClick={() => onBackButtonClick()} />
          ) : (
            <MenuButton onClick={() => setDrawerVisible(true)} />
          )}
        </div>
        <span className="font-semibold text-white text-lg">
          {appBarInterface.title}
        </span>
        <div className="w-12 flex items-center justify-center">
          <img
            src={Logo}
            onClick={() => {
              if (location.pathname !== '/start') {
                navigate('/start');
              }
            }}
          />
        </div>
      </div>
      <Drawer
        title={<div className="text-white text-xl font-semibold">Safelife</div>}
        closeIcon={<CloseOutlined className="text-white pt-1" />}
        placement="left"
        onClose={(): void => setDrawerVisible(false)}
        open={drawerVisible}
        bodyStyle={drawerBodyStyle}
        headerStyle={drawerHeaderStyle}
        contentWrapperStyle={{ backgroundColor: colors.safeLifeMedium }}>
        {props.nav.map((nav, key) => {
          return <DrawerRow key={key} nav={nav} onClick={onDrawerRowClick} />;
        })}

        <div className="grow-default"></div>
        <div className="flex flex-row items-center">
          <LogoutOutlined className="text-lg pr-2" />
          <span
            className="cursor-pointer text-md"
            onClick={() => setToken(null)}>
            {t('common.signOut')}
          </span>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default AppBar;
