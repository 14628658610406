import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CourseInstanceAttendeeStatus } from '../types/CourseInstance';
import Passed from '../resources/img/passed.svg';
import Failed from '../resources/img/failed.svg';
import Participated from '../resources/img/participated.svg';
import DidNotParticipate from '../resources/img/didNotParticipate.svg';

type CourseInstanceAttendeeStatusBoxProps = {
  status: CourseInstanceAttendeeStatus;
  simplified?: boolean;
  onClick?: () => void;
};

export const CourseInstanceAttendeeStatusBox: FC<
  CourseInstanceAttendeeStatusBoxProps
> = ({ status, simplified, onClick }) => {
  const { t } = useTranslation();

  switch (status) {
    case CourseInstanceAttendeeStatus.Passed:
      return (
        <div
          className="flex gap-2"
          style={{ flexDirection: simplified ? 'row-reverse' : 'row' }}
          onClick={onClick}>
          <img src={Participated} className="w-4" />
          <img src={Passed} className="w-4" />
          {!simplified && t('views.CourseInstanceAttendees.statusPassed')}
        </div>
      );
    case CourseInstanceAttendeeStatus.Failed:
      return (
        <div
          className="flex gap-2"
          style={{ flexDirection: simplified ? 'row-reverse' : 'row' }}
          onClick={onClick}>
          <img src={Participated} className="w-4" />
          <img src={Failed} className="w-4" />
          {!simplified && t('views.CourseInstanceAttendees.statusFailed')}
        </div>
      );
    case CourseInstanceAttendeeStatus.DidNotParticipate:
      return (
        <div
          className="flex gap-2"
          style={{ flexDirection: simplified ? 'row-reverse' : 'row' }}
          onClick={onClick}>
          <img src={DidNotParticipate} className="w-4" />
          {!simplified &&
            t('views.CourseInstanceAttendees.statusDidNotParticipate')}
        </div>
      );
  }
};
