import User from '../types/User';

import client from './client';

type LoginParams = {
  email: string;
  password: string;
};

export const login = (params: LoginParams) =>
  client.post<{ user: User; jwt: string }>('/users/login', params);

type ResetPasswordRequestParams = {
  email: string;
};

export const resetPasswordRequest = (params: ResetPasswordRequestParams) =>
  client.post('/users/m/reset-password-request', params);

type ResetPasswordParams = {
  token: string;
  password: string;
};

export const resetPassword = (params: ResetPasswordParams) =>
  client.post('/users/reset-password', params);
