// eslint-disable-next-line import/order
import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from 'i18next';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import AppBar from './components/AppBar';
import * as API from './api';
import Login from './views/Login';
import { useToken } from './context/token';
import { useUser } from './context/user';
import Start from './views/Start';
import Profile from './views/Profile';
import CourseInstanceDetails from './views/CourseInstanceDetails';
import AppContainer from './components/AppContainer';
import CourseInstanceCalendar from './views/CourseInstanceCalendar';
import SafeLifeSpin from './components/SafeLifeSpin';
import Availability from './views/Availability';
import ResetPassword from './views/ResetPassword';
import { useLanguage } from './context/language';
import './i18n/config';
import CourseInstanceAttendees from './views/CourseInstanceAttendees';

const queryClient = new QueryClient();

const App: React.FC = () => {
  const [token, setToken] = useToken();
  const [currentUser, setCurrentUser] = useUser();
  const { t } = useTranslation();
  const [, setLanguage] = useLanguage();

  useEffect(() => {
    if (token) {
      fetchUser();
    }
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchUser = async () => {
    try {
      const { data } = await API.getProfile();
      setCurrentUser(data);
      changeLanguage(data.preferredLanguage?.code);
      setLanguage(data.preferredLanguage);
    } catch (error) {
      if (
        isAxiosError(error) &&
        error.response &&
        error.response.status === 401
      ) {
        setToken(null);
      }
    }
  };
  return (
    <AppContainer>
      <QueryClientProvider client={queryClient}>
        {token !== null && currentUser !== null ? (
          <React.Fragment>
            <AppBar
              nav={[
                { path: '/start', text: t('App.startPage') },
                { path: '/profil', text: t('App.myProfile') },
                { path: '/kalender', text: t('common.calendar') },
              ]}
            />
            <Routes>
              <Route path="/start" element={<Start />} />
              <Route path="/profil" element={<Profile />} />
              <Route
                path="/kalender/:date?"
                element={<CourseInstanceCalendar />}
              />
              <Route
                path="/kurstillfallen/:id/attendees"
                element={<CourseInstanceAttendees />}
              />
              <Route
                path="/kurstillfallen/:id"
                element={<CourseInstanceDetails />}
              />
              <Route
                path="/tillganglighet/:start/:end"
                element={<Availability />}
              />
              <Route path="*" element={<Navigate replace to="/start" />} />
            </Routes>
          </React.Fragment>
        ) : token === null ? (
          <Routes>
            <Route path="/nytt-losenord" element={<ResetPassword />} />
            <Route path="*" element={<Login />} />
          </Routes>
        ) : (
          <div className="flex justify-center items-center h-screen">
            <SafeLifeSpin size="large" />
          </div>
        )}
      </QueryClientProvider>
    </AppContainer>
  );
};

export default App;
