import React from 'react';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { formatCourseInstancesString } from '../utils/courseInstance';

import SafeLifeSpin from './SafeLifeSpin';

type PotentialCourseInstancesCardProps = {
  start: Dayjs;
  end: Dayjs;
  matchedCount?: number;
  potentialCount?: number;
  loading?: boolean;
  onClick?: () => void;
  className?: string;
};

const PotentialCourseInstancesCard: React.FC<
  PotentialCourseInstancesCardProps
> = ({
  start,
  end,
  matchedCount,
  potentialCount,
  loading,
  onClick,
  className,
}) => {
  const { t } = useTranslation();

  const isSameMonth = start.month() == end.month();

  const [backgroundColor, borderColor] = isSameMonth
    ? ['bg-blueGrayLight', 'border-blueGrayMedium']
    : ['bg-warningBG', 'border-warningYellow'];

  const headline = isSameMonth
    ? `${t(
        'components.PotentialCourseInstanceCard.potentialCourseInstancesIn',
      )} ${start.format('MMMM')}`
    : `${t('common.courseInstances')} ${start.format('MMMM')} - ${end.format(
        'MMMM',
      )}`;

  return (
    <>
      {loading ? (
        <div
          className={`w-full rounded-lg p-2 flex justify-center items-center cursor-pointer shadow-md bg-white border-solid border-blueGrayLight border-1 ${className}`}>
          <SafeLifeSpin size="large" className="py-5 " />
        </div>
      ) : (
        <div
          className={`w-full rounded-lg p-2 flex cursor-pointer shadow-md ${backgroundColor} border-solid ${borderColor} border-1 ${className}`}
          onClick={onClick}>
          <div className="flex-1 p-2">
            <div className="text-lg font-semibold mb-1">{headline}</div>
            {matchedCount != undefined && matchedCount > 0 && (
              <div className="text-sm pt-1">
                <span className="font-semibold">{`${matchedCount} ${formatCourseInstancesString(
                  matchedCount,
                  t,
                )}`}</span>
                <span>
                  {' '}
                  {t(
                    'components.PotentialCourseInstanceCard.matchesAvailability',
                  )}
                </span>
              </div>
            )}
            {potentialCount !== undefined && potentialCount > 0 && (
              <div className="text-sm pt-1">
                <span className="font-semibold">{`${potentialCount} ${formatCourseInstancesString(
                  potentialCount,
                  t,
                )}`}</span>
                <span>
                  {' '}
                  {t(
                    'components.PotentialCourseInstanceCard.outsideSpecifiedTimes',
                  )}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PotentialCourseInstancesCard;
