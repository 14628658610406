import React, { useState } from 'react';
import { capitalize } from 'lodash';
import dayjs from 'dayjs';
import { Modal, message } from 'antd';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import * as API from '../api';
import { useUser } from '../context/user';
import Warning from '../resources/img/warning_icon.svg';
import { removeCountryFromAddress } from '../utils/address';

import SafeLifeSpin from './SafeLifeSpin';

type Props = {
  courseInstanceId?: number;
  startsAt?: string;
  endsAt?: string;
  clientName?: string;
  address?: string;
  open?: boolean;
  onCancel: () => void;
  onSuccess: () => void;
};

const AcceptCourseInstanceModal: React.FC<Props> = ({
  courseInstanceId,
  startsAt,
  endsAt,
  clientName,
  address,
  open,
  onCancel,
  onSuccess,
}) => {
  const [sendingRequest, setSendingRequest] = useState<boolean>(false);
  const currentUser = useUser()[0];
  const { t } = useTranslation();

  const onSave = async () => {
    if (!courseInstanceId || !currentUser || currentUser.id == null) {
      return;
    }
    setSendingRequest(true);
    try {
      await API.acceptCourseInstance(courseInstanceId, {
        userId: currentUser.id,
      });
    } catch (error) {
      if (isAxiosError(error) && error.response?.status == 409) {
        message.error(error.response.data.message);
      } else {
        message.error(t('errors.somethingWentWrong'));
      }
    } finally {
      onSuccess();
      setSendingRequest(false);
    }
  };

  const getDateText = () => {
    return (
      capitalize(dayjs(startsAt).format('dddd DD')) +
      ' ' +
      dayjs(startsAt).format('MMMM')
    );
  };

  const getTimeText = () => {
    return (
      dayjs(startsAt).format('HH:mm') + ' - ' + dayjs(endsAt).format('HH:mm')
    );
  };

  return (
    <Modal
      title={t('components.AcceptCourseInstanceModal.acceptCourseInstance')}
      onOk={() => onSave()}
      okButtonProps={{
        className: 'bg-safeLifeDark text-center',
        disabled: sendingRequest,
      }}
      okText={t('common.yes')}
      onCancel={onCancel}
      cancelText={t('common.cancel')}
      cancelButtonProps={{ disabled: sendingRequest }}
      open={open}
      className="px-3"
      closable={false}
      centered>
      <div className="flex flex-row">
        {sendingRequest ? (
          <div className="w-full flex justify-center items-center py-8">
            <SafeLifeSpin size="large" />
          </div>
        ) : (
          <>
            <div>
              <div className="flex flex-row">
                <img src={Warning} className="self-start pt-3 pr-3" />
                <div className="text-base font-medium">
                  <div className="pb-2">
                    {t(
                      'components.AcceptCourseInstanceModal.confirmCourseInstance',
                    )}
                  </div>
                  <div className="text-lg font-medium pt-2">{clientName}</div>
                  <div className="text-base font-medium pt-2">
                    {removeCountryFromAddress(address)}
                  </div>
                  <div className="text-base font-medium">{getDateText()}</div>
                  <div className="text-sm font-normal">{getTimeText()}</div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default AcceptCourseInstanceModal;
