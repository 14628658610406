import React from 'react';
import dayjs from 'dayjs';
import { capitalize, range } from 'lodash';

type WeekdayButtonProps = {
  weekday: number;
  selected: boolean;
  disabled: boolean;
  onClick: (weekday: number) => void;
};

const WeekdayButton: React.FC<WeekdayButtonProps> = ({
  weekday,
  selected,
  disabled,
  onClick,
}) => {
  const getClassNames = () => {
    if (disabled) {
      return 'bg-gray-200 border-gray-400 text-gray-500';
    }

    if (selected) {
      return 'bg-blueGrayMedium border-safeLifeLight';
    } else {
      return 'bg-white border-blueGrayMedium';
    }
  };

  return (
    <div
      className={`${getClassNames()} flex h-11 w-11 rounded-full items-center justify-center border-solid	border-2 transition-all	duration-200`}
      onClick={() => {
        if (!disabled) {
          onClick(weekday);
        }
      }}>
      <div className="font-medium text-base">
        {capitalize(dayjs().isoWeekday(weekday).format('dd'))}
      </div>
    </div>
  );
};

type WeekdaySelectorProps = {
  selectedWeekdays: Set<number>;
  setSelectedWeekdays: React.Dispatch<React.SetStateAction<Set<number>>>;
  selectableWeekdays: Set<number>;
};

const WeekdaySelector: React.FC<WeekdaySelectorProps> = ({
  selectedWeekdays,
  setSelectedWeekdays,
  selectableWeekdays,
}) => {
  const weekdayClicked = (weekday: number) => {
    const newState = selectedWeekdays;
    if (selectedWeekdays.has(weekday)) {
      newState.delete(weekday);
    } else {
      newState.add(weekday);
    }
    setSelectedWeekdays(new Set(newState));
  };

  return (
    <div className="flex flex-row justify-between pt-1.5 pb-3">
      {range(1, 8).map((weekday) => (
        <WeekdayButton
          key={weekday}
          weekday={weekday}
          selected={selectedWeekdays.has(weekday)}
          disabled={!selectableWeekdays.has(weekday)}
          onClick={() => weekdayClicked(weekday)}
        />
      ))}
    </div>
  );
};

export default WeekdaySelector;
