import sv_SE from 'antd/es/date-picker/locale/sv_SE';
import en_GB from 'antd/es/date-picker/locale/en_GB';

export const getAntdLocale = (code?: string) => {
  switch (code) {
    case 'sv':
      return sv_SE;
    case 'en':
      return en_GB;
    default:
      return en_GB;
  }
};
