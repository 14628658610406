import dayjs from 'dayjs';
import { changeLanguage } from 'i18next';
import React, { useState, useContext, useEffect } from 'react';
import 'dayjs/locale/sv';
import 'dayjs/locale/en-gb';

import Language from '../types/Language';
import client from '../api/client';

const LanguageContext = React.createContext<
  [Language | undefined, (language: Language | undefined) => void]
>([undefined, () => {}]);

export const useLanguage = () => useContext(LanguageContext);

type LanguageContextProviderProps = {
  children: React.ReactChild;
  value?: Language | undefined;
};

const LanguageContextProvider: React.FC<LanguageContextProviderProps> = ({
  children,
  value,
}) => {
  const [language, setLanguage] = useState<Language | undefined>(value);

  useEffect(() => {
    if (language) {
      dayjs.locale(language.code);
      changeLanguage(language.code);
      client.defaults.headers.common['Accept-Language'] = language.code;
    }
  }, [language]);

  return (
    <LanguageContext.Provider value={[language, setLanguage]}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;
