import React, { useEffect, useState } from 'react';
import { capitalize, range } from 'lodash';
import { Button, Radio, Modal, Alert } from 'antd';
import { DownOutlined, EditOutlined, UpOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { useAppBar } from '../context/AppBar';
import { useAppContainer } from '../context/AppContainer';
import SafeLifeSpin from '../components/SafeLifeSpin';
import TimeIntervalPicker, {
  defaultTimeSlot,
  stringifyTime,
  TimeInterval,
} from '../components/TimeIntervalPicker';
import CourseInstanceLimitPicker from '../components/CourseInstanceLimitPicker';
import AvailabilityPreset from '../types/AvailabilityPreset';
import * as API from '../api';
import { useUser } from '../context/user';
import Warning from '../resources/img/warning_icon.svg';
import {
  formatCourseInstancesString,
  formatCourseInstancesStringDefinite,
} from '../utils/courseInstance';
import { useCalendar } from '../context/Calendar';
import WeekdaySelector from '../components/WeekdaySelector';
import PresetIntervalPicker, {
  availabilityPresetToTimeInterval,
} from '../components/PresetIntervalPicker';

type ContentCellProps = {
  title: string;
  titleAddition?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
};

const ContentCell: React.FC<ContentCellProps> = ({
  title,
  titleAddition,
  children,
  className,
}) => {
  return (
    <div className={`flex flex-col pb-4 ${className}`}>
      <div className="flex justify-between">
        <span className="text-blueGrayDark pb-1 text-base font-medium">
          {title}
        </span>
        {titleAddition}
      </div>
      <div>{children}</div>
    </div>
  );
};

type PresetTimePickerProps = {
  presets: TimeInterval[];
  selectedPresets: Set<AvailabilityPreset['id']>;
  selectPreset: (id: number) => void;
  openModal: () => void;
};

const PresetTimePicker: React.FC<PresetTimePickerProps> = ({
  presets,
  selectedPresets,
  selectPreset,
  openModal,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <div className="rounded-md border-solid border-1 border-blue-300 bg-blue-100 overflow-hidden">
      <div className="flex justify-between items-center p-2">
        <div>
          <div className="text-base font-medium text-gray-700 pb-1">
            {t('views.Availability.usePresetIntervals')}
          </div>
          <div>{t('views.Availability.yourPresetIntervals')}</div>
        </div>
        <Button
          shape="circle"
          size="small"
          className="m-1.5"
          icon={open ? <UpOutlined /> : <DownOutlined />}
          onClick={() => setOpen((currentValue) => !currentValue)}
        />
      </div>
      <div
        className="transition-all ease-in-out duration-200"
        style={{ maxHeight: open ? '1000px' : '0px' }}>
        <div className="p-3 flex flex-col items-center">
          {presets.length > 0 ? (
            <div
              className={
                presets.length > 1
                  ? 'grid grid-cols-2 gap-2'
                  : 'flex justify-center'
              }>
              {presets.map((preset) => (
                <Button
                  key={preset.id}
                  style={{ color: 'black' }}
                  className={
                    selectedPresets.has(preset.id!)
                      ? 'border-blue-600 bg-blue-200'
                      : undefined
                  }
                  onClick={() => selectPreset(preset.id!)}>{`${stringifyTime(
                  preset.start,
                )} - ${stringifyTime(preset.end)}`}</Button>
              ))}
            </div>
          ) : (
            <div className="font-medium">
              {t('views.Availability.noPresetTimeInterval')}
            </div>
          )}
          <Button
            type="link"
            onClick={openModal}
            className="border-none mt-1"
            icon={<EditOutlined />}>
            {t('views.Availability.presetsEdit')}
          </Button>
        </div>
      </div>
    </div>
  );
};

type AvailabilityParams = {
  start: string;
  end: string;
};

const Availability = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [, setAppBarInterface] = useAppBar();
  const [, setCurrentClasses] = useAppContainer();
  const { start: startParam, end: endParam } = useParams<AvailabilityParams>();

  const [loadingHours, setLoadingHours] = useState(true);
  const [available, setAvailable] = useState(true);
  const [selectedCourseInstanceLimit, setSelectedCourseInstanceLimit] =
    useState(0);
  const [selectedWeekdays, setSelectedWeekdays] = useState<Set<number>>(
    new Set<number>(),
  );
  const [selectableWeekdays, setSelectabledWeekdays] = useState<Set<number>>(
    new Set<number>(),
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [presets, setPresets] = useState<TimeInterval[]>([]);
  const [loadingPresets, setLoadingPresets] = useState(true);
  const [sendingRequest, setSendingRequest] = useState<boolean>(false);
  const [selectedPresetIds, setSelectedPresetIds] = useState<Set<number>>(
    new Set(),
  );
  const [selectedIntervals, setSelectedIntervals] = useState<TimeInterval[]>([
    { ...defaultTimeSlot },
  ]);
  const [noOverlappingIntervals, setNoOverlappingIntervals] =
    useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const calendarState = useCalendar()[0];
  const currentUser = useUser()[0];
  const { t } = useTranslation();

  const start = dayjs(startParam);
  const end = dayjs(endParam);
  const singleDay = start.isSame(end, 'day');

  useEffect(() => {
    setAppBarInterface({ title: t('views.Availability.changeAvailability') });
  }, [i18next.language]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCurrentClasses('bg-white');
    fetchPresets();
    fetchAvailability();

    const weekdays = getSelectedWeekdays();
    setSelectedWeekdays(new Set(weekdays));
    setSelectabledWeekdays(new Set(weekdays));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getSelectedWeekdays = () => {
    if (end.diff(start, 'week') >= 1) {
      return range(1, 8);
    }

    const numberOfDays = end.add(1, 'day').diff(start, 'day');

    const weekdays = range(0, numberOfDays).map((offset) =>
      start.add(offset, 'days').isoWeekday(),
    );

    return weekdays;
  };

  const fetchPresets = () => {
    if (!currentUser) return;

    API.getUserAvailabilityPresets(currentUser.id)
      .then(({ data }) =>
        setPresets(
          data
            .sort((a, b) => {
              if (a.startsAt > b.endsAt) return 1;
              if (a.startsAt < b.endsAt) return -1;
              return a.endsAt > b.endsAt ? 1 : -1;
            })
            .map<TimeInterval>(availabilityPresetToTimeInterval),
        ),
      )
      .finally(() => setLoadingPresets(false));
  };

  const fetchAvailability = async () => {
    if (!currentUser) {
      return;
    }

    try {
      const { data } = await API.getAvailability(currentUser.id, {
        startDate: start.format('YYYY-MM-DD'),
        endDate: end.format('YYYY-MM-DD'),
      });

      let courseInstanceLimit = 0;
      data.forEach((availabilityInfo) => {
        courseInstanceLimit = Math.max(
          courseInstanceLimit,
          availabilityInfo.courseInstanceLimit,
        );
      });

      const intervals: TimeInterval[] = data[0].timeSlots
        .filter((timeSlot) =>
          data.every(({ timeSlots }) =>
            timeSlots.some(
              (match) =>
                dayjs(match.startsAt).format('HH:mm') ===
                  dayjs(timeSlot.startsAt).format('HH:mm') &&
                dayjs(match.endsAt).format('HH:mm') ===
                  dayjs(timeSlot.endsAt).format('HH:mm'),
            ),
          ),
        )
        .map((timeslot) => ({
          start: {
            hour: Number(dayjs(timeslot.startsAt).format('HH')),
            minute: Number(dayjs(timeslot.startsAt).format('mm')),
          },
          end: {
            hour: Number(dayjs(timeslot.endsAt).format('HH')),
            minute: Number(dayjs(timeslot.endsAt).format('mm')),
          },
        }));
      const anyIntervals = data.some(({ timeSlots }) => timeSlots.length > 0);

      if (!intervals.length && anyIntervals) setNoOverlappingIntervals(true);
      if (intervals.length) setSelectedIntervals(intervals);
      setSelectedCourseInstanceLimit(courseInstanceLimit);
    } finally {
      setLoadingHours(false);
    }
  };

  const dateString = () => {
    return singleDay
      ? capitalize(start.format('D MMMM'))
      : capitalize(start.format('D')) +
          ' - ' +
          capitalize(end.format('D MMMM'));
  };

  const onSave = async () => {
    if (!currentUser) {
      return;
    }

    const timeZone =
      currentUser.market.timeZoneId ??
      Intl.DateTimeFormat().resolvedOptions().timeZone;

    let params;
    if (available) {
      params = {
        timeIntervals: mergeTimeIntervals([
          ...selectedIntervals,
          ...presets.filter(({ id }) => selectedPresetIds.has(id ?? NaN)),
        ]).map((interval) => ({
          start: stringifyTime(interval.start),
          end: stringifyTime(interval.end),
        })),
        courseInstanceLimit: selectedCourseInstanceLimit,
        startDate: start.format('YYYY-MM-DD'),
        endDate: end.format('YYYY-MM-DD'),
        timeZone: timeZone,
        weekdays: Array.from(selectedWeekdays),
      };
    } else {
      params = {
        timeIntervals: [],
        courseInstanceLimit: currentUser.defaultCourseInstanceLimit,
        startDate: start.format('YYYY-MM-DD'),
        endDate: end.format('YYYY-MM-DD'),
        timeZone: timeZone,
        weekdays: Array.from(selectedWeekdays),
      };
    }

    setSendingRequest(true);
    try {
      await API.batchAddAvailability(currentUser.id, params);
    } finally {
      if (location.state) {
        navigate(-1);
      } else {
        navigate('/kalender');
      }
      setSendingRequest(false);
    }
  };

  const RadioRow: React.FC<{
    text: string;
    selectionValue: boolean;
    selected: boolean;
  }> = ({ text, selectionValue, selected }) => {
    return (
      <div
        className={`${
          selected && 'font-medium'
        } flex grow-default items-center	text-base justify-between border-solid border-blueGrayMedium border-0 border-b-1 py-2`}
        onClick={() => setAvailable(selectionValue)}>
        {text}
        <Radio value={selectionValue} />
      </div>
    );
  };

  const Footer: React.FC = () => {
    return (
      <div className="grid grid-cols-2 justify-center bg-white border-solid border-0 border-t-1 border-blueGrayMedium gap-6 p-4 footer-shadow">
        <Button
          className="flex grow-default font-medium text-base bg-safeLifeMedium border-safeLifeMedium text-white focus:opacity-50 items-center justify-center"
          onClick={() => {
            setShowModal(true);
          }}>
          <div>{t('common.save')}</div>
        </Button>
        <Button
          className="flex grow-default font-medium text-base items-center justify-center"
          onClick={() => {
            if (location.state) {
              navigate(-1);
            } else {
              navigate('/start');
            }
          }}>
          <div>{t('common.cancel')}</div>
        </Button>
      </div>
    );
  };

  return (
    <>
      {!loadingHours && !loadingPresets ? (
        <>
          <Modal
            open={modalOpen}
            title={t('views.Availability.presetAvailabilityIntervals')}
            onCancel={() => setModalOpen(false)}
            onOk={() => setModalOpen(false)}
            centered>
            <PresetIntervalPicker
              presets={presets}
              setPresets={setPresets}
              loadingPresets={loadingPresets}
            />
          </Modal>
          <div className="pt-4 pb-12 view-max-width">
            <div className="px-3 py-2">
              <div className="text-2xl font-medium mt-2 mb-6">
                {dateString()}
              </div>
              {noOverlappingIntervals && (
                <Alert
                  type="warning"
                  className="my-4"
                  message={t('views.Availability.mixedIntervalsWarningMessage')}
                  description={t(
                    'views.Availability.mixedIntervalsWarningDescription',
                  )}
                />
              )}
              <ContentCell title={t('common.availability')}>
                <div className="text-xl">
                  <Radio.Group
                    className="w-full text-lg"
                    size="large"
                    defaultValue={available}
                    value={available}>
                    <RadioRow
                      text={t('views.Availability.noAvailability')}
                      selectionValue={false}
                      selected={!available}
                    />
                    <RadioRow
                      text={t('views.Availability.availability')}
                      selectionValue={true}
                      selected={available}
                    />
                  </Radio.Group>
                </div>
              </ContentCell>
              {available && (
                <div>
                  <ContentCell
                    title={t('views.Availability.courseInstancePerDay')}>
                    <CourseInstanceLimitPicker
                      className="mt-2"
                      courseInstanceLimit={selectedCourseInstanceLimit}
                      maxCourseInstanceLimit={4}
                      setCourseInstanceLimit={setSelectedCourseInstanceLimit}
                    />
                  </ContentCell>
                  <ContentCell title={t('views.Availability.timeInterval')}>
                    <WeekdaySelector
                      selectableWeekdays={selectableWeekdays}
                      selectedWeekdays={selectedWeekdays}
                      setSelectedWeekdays={setSelectedWeekdays}
                    />
                    <div className="pb-4 flex justify-center">
                      <TimeIntervalPicker
                        onAddInterval={(interval) =>
                          setSelectedIntervals((selectedIntervals) => [
                            ...selectedIntervals,
                            interval,
                          ])
                        }
                        onUpdateInterval={(row, type, { hour, minute }) => {
                          const currentInterval = selectedIntervals[row];
                          let [newEndHour, newEndMinute] = [
                            currentInterval.end.hour,
                            currentInterval.end.minute,
                          ];
                          if (type === 'start') {
                            const [oldEndHour, oldEndMinute] = [
                              currentInterval.end.hour,
                              currentInterval.end.minute,
                            ];
                            if (
                              hour > oldEndHour ||
                              (hour === oldEndHour && minute > oldEndMinute)
                            ) {
                              [newEndHour, newEndMinute] =
                                minute === 30 ? [hour + 1, 0] : [hour, minute];
                            }
                          }
                          setSelectedIntervals?.([
                            ...selectedIntervals.slice(0, row),
                            type === 'start'
                              ? {
                                  start: { hour, minute },
                                  end: {
                                    hour: newEndHour,
                                    minute: newEndMinute,
                                  },
                                }
                              : {
                                  start: {
                                    hour: currentInterval.start.hour,
                                    minute: currentInterval.start.minute,
                                  },
                                  end: { hour, minute },
                                },
                            ...selectedIntervals.slice(row + 1),
                          ]);
                        }}
                        onDeleteInterval={async (row) =>
                          setSelectedIntervals((selectedIntervals) => [
                            ...selectedIntervals.slice(0, row),
                            ...selectedIntervals.slice(row + 1),
                          ])
                        }
                        selectedIntervals={selectedIntervals}
                        setSelectedIntervals={setSelectedIntervals}
                      />
                    </div>
                    <PresetTimePicker
                      presets={presets}
                      selectedPresets={selectedPresetIds}
                      selectPreset={(id) => {
                        const newState = selectedPresetIds;
                        if (selectedPresetIds.has(id)) {
                          newState.delete(id);
                        } else {
                          newState.add(id);
                        }
                        setSelectedPresetIds(new Set(newState));
                      }}
                      openModal={() => setModalOpen(true)}
                    />
                  </ContentCell>
                </div>
              )}
            </div>
          </div>
          <div className="fixed bottom-0 w-full">
            <Footer />
          </div>
        </>
      ) : (
        <div className="w-full flex justify-center items-center mt-64	absolute z-10">
          <SafeLifeSpin size="large" />
        </div>
      )}
      <Modal
        title={t('views.Availability.changeAvailability')}
        onOk={() => onSave()}
        okButtonProps={{
          className: 'bg-safeLifeDark text-center',
          disabled: sendingRequest,
        }}
        okText={t('views.Availability.yesChange')}
        onCancel={() => setShowModal(false)}
        cancelText={t('common.cancel')}
        cancelButtonProps={{ disabled: sendingRequest }}
        open={showModal}
        className="px-3"
        closable={false}
        centered>
        <div className="flex flex-row">
          {sendingRequest ? (
            <div className="w-full flex justify-center items-center py-8">
              <SafeLifeSpin size="large" />
            </div>
          ) : (
            <>
              <div className="w-full">
                <div className="flex flex-row">
                  <img src={Warning} className="self-start pt-3 pr-3" />
                  <div className="text-base font-medium">
                    {t(
                      selectedIntervals.filter(
                        ({ start, end }) =>
                          stringifyTime(start) < stringifyTime(end),
                      ).length ||
                        presets.filter(({ id }) => selectedPresetIds.has(id!))
                          .length
                        ? 'views.Availability.changeAvailabilityFor'
                        : 'views.Availability.clearAvailabilityFor',
                      {
                        count: Math.abs(start.diff(end, 'days')) + 1,
                      },
                    )}
                  </div>
                </div>
                {selectedIntervals.filter(
                  ({ start, end }) => stringifyTime(start) < stringifyTime(end),
                ).length ||
                presets.filter(({ id }) => selectedPresetIds.has(id!))
                  .length ? (
                  <div className="pt-4">
                    <TimeIntervalPicker
                      selectedIntervals={mergeTimeIntervals([
                        ...selectedIntervals,
                        ...presets.filter(({ id }) =>
                          selectedPresetIds.has(id!),
                        ),
                      ])}
                    />
                  </div>
                ) : null}
                {calendarState.selectedCourseInstancesCount > 0 && (
                  <>
                    <div className="text-base font-normal pt-2 pl-10">
                      {t('views.Availability.payAttention')}{' '}
                      {calendarState.selectedCourseInstancesCount}{' '}
                      {formatCourseInstancesString(
                        calendarState.selectedCourseInstancesCount,
                        t,
                      )}{' '}
                      {t('views.Availability.withinTimeInterval')}
                    </div>
                    <div className="pt-3 px-6">
                      <Alert
                        type="warning"
                        className="text-base font-normal"
                        message={`${capitalize(
                          formatCourseInstancesStringDefinite(
                            calendarState.selectedCourseInstancesCount,
                            t,
                          ),
                        )} ${t('views.Availability.noChangeOrCancel')}`}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

const mergeTimeIntervals = (timeIntervals: TimeInterval[]) => {
  const sorted = timeIntervals
    .filter(
      (interval) => stringifyTime(interval.start) < stringifyTime(interval.end),
    )
    .sort((a, b) =>
      stringifyTime(a.start) === stringifyTime(b.start)
        ? 0
        : stringifyTime(a.start) > stringifyTime(b.start)
          ? 1
          : -1,
    );
  const merged: TimeInterval[] = [];
  sorted.forEach((interval, i) => {
    const existingIndex = merged.findIndex(
      (existing) =>
        !(stringifyTime(interval.start) > stringifyTime(existing.end)),
    );
    if (existingIndex < 0) return merged.push({ ...interval });

    if (stringifyTime(interval.end) < stringifyTime(merged[existingIndex].end))
      return;
    merged[existingIndex].end = { ...interval.end };
  });

  return merged;
};

export default Availability;
