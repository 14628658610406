import React, { useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button } from 'antd';
import { ClearOutlined, EditOutlined, SaveFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import * as API from '../api';
import User from '../types/User';

type ProfileSignatureProps = {
  user: User | null;
  fetchUser: () => void;
};

const ProfileSignature: React.FC<ProfileSignatureProps> = ({
  user,
  fetchUser,
}) => {
  const { t } = useTranslation();
  const [submittingSignature, setSubmittingSignature] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [enableSave, setEnableSave] = useState(false);

  const signCanvas = React.useRef() as React.MutableRefObject<SignatureCanvas>;

  const updateSignature = async () => {
    signCanvas.current.getTrimmedCanvas().toBlob((blob) => {
      if (!blob || !user?.id) {
        return null;
      }

      const file = new File([blob], `${user.email}_signature.png`, {
        type: 'image/png',
      });
      const formData = new FormData();
      formData.append('image', file);
      formData.append('extension', 'png');

      setSubmittingSignature(true);
      API.putUserCertificateSignature(user.id, formData)
        .then(() => fetchUser())
        .finally(() => setSubmittingSignature(false));
    }, 'image/png');
  };

  return (
    <>
      {editMode ? (
        <>
          <div className="bg-white border-solid border-blueGrayMedium rounded-md w-full h-[120px] md:h-[260px] my-3">
            <SignatureCanvas
              onBegin={() => setEnableSave(true)}
              penColor="black"
              canvasProps={{ className: 'w-full h-full' }}
              ref={signCanvas}
            />
          </div>
          <div className="flex justify-center gap-6">
            <Button className="w-20" onClick={() => setEditMode(false)}>
              {t('common.cancel')}
            </Button>
            <Button
              icon={<ClearOutlined />}
              className="w-20 pr-[4.5rem]"
              onClick={() => {
                signCanvas.current.clear();
                setEnableSave(false);
              }}>
              {t('components.ProfileSignature.clear')}
            </Button>
            <Button
              disabled={!enableSave}
              icon={<SaveFilled />}
              loading={submittingSignature}
              type="primary"
              className="w-20 pr-[4.5rem]"
              onClick={() => updateSignature()}>
              {t('common.save')}
            </Button>
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center w-full">
          <div className="m-3 max-w-1/2">
            {!!user?.certificateSignature ? (
              <img
                src={user?.certificateSignature.url}
                className="w-full max-h-[150px]"
              />
            ) : (
              <div className="text-base my-3">
                {t('components.ProfileSignature.noSignature')}
              </div>
            )}
          </div>
          <div className="w-full border-0 border-t-1 border-gray-400 border-solid flex justify-center">
            <Button className="mt-3" onClick={() => setEditMode(true)}>
              <EditOutlined />
              {t('components.ProfileSignature.editSignature')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileSignature;
