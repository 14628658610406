import dayjs from 'dayjs';

type TFunction = (key: string) => string;

export const formatCourseInstancesString = (
  nCourseInstances: number,
  t: TFunction,
) => {
  return nCourseInstances == 1
    ? t('utils.courseInstances.courseInstance')
    : t('utils.courseInstances.courseInstances');
};

export const formatCourseInstancesStringDefinite = (
  nCourseInstances: number,
  t: TFunction,
) => {
  return nCourseInstances == 1
    ? t('utils.courseInstances.theCourseInstance')
    : t('utils.courseInstances.theCourseInstances');
};

export const courseInstanceIsAcceptable = (
  instructorId?: number | null,
  startsAt?: string,
) => {
  return instructorId === null && dayjs(startsAt).isAfter(dayjs());
};
