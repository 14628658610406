import React from 'react';

import { Geolocation } from '../types/Location';

type Props = {
  pinLocation: Geolocation;
  className: string;
  width: number;
  height: number;
};

const GoogleMap: React.FC<Props> = ({
  pinLocation,
  className,
  width,
  height,
}) => {
  const apiKey = process.env.REACT_APP_MAPS_API_KEY;

  return (
    <a
      href={`https://maps.google.com/?q=@${pinLocation.lat},${pinLocation.lng}`}
      target="_blank"
      rel="noreferrer">
      <img
        className={className}
        src={`https://maps.googleapis.com/maps/api/staticmap?size=${width}x${height}&scale=${2}&markers=${
          pinLocation.lat
        },${pinLocation.lng}&key=${apiKey}`}
      />
    </a>
  );
};

export default GoogleMap;
