import { Button } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { Dictionary, groupBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import TimeSlot from '../types/TimeSlot';
import { getTimeOfDay } from '../utils/date';

import WarningIcon from './WarningIcon';

const getEarliestTimeSlot = (timeSlots: TimeSlot[]) => {
  return timeSlots.reduce((prev, curr) =>
    getTimeOfDay(dayjs(prev.startsAt)).isBefore(
      getTimeOfDay(dayjs(curr.startsAt)),
    )
      ? prev
      : curr,
  );
};

const getLatestTimeSlot = (timeSlots: TimeSlot[]) => {
  return timeSlots.reduce((prev, curr) =>
    getTimeOfDay(dayjs(prev.endsAt)).isAfter(getTimeOfDay(dayjs(curr.endsAt)))
      ? prev
      : curr,
  );
};

const checkTimeSlotsForWarning = (timeSlotsByDay: Dictionary<TimeSlot[]>) => {
  const dates = Object.keys(timeSlotsByDay);
  const firstTimeSlot = timeSlotsByDay[dates[0]][0];
  const firstTimeSlotStart = dayjs(firstTimeSlot.startsAt).hour();
  const firstTimeSlotEnd = dayjs(firstTimeSlot.endsAt).hour();

  return Object.values(timeSlotsByDay).some(
    (timeSlots) =>
      timeSlots.length > 1 ||
      dayjs(timeSlots[0].startsAt).hour() !== firstTimeSlotStart ||
      dayjs(timeSlots[0].endsAt).hour() !== firstTimeSlotEnd,
  );
};

type Props = {
  selectedDates: { start: Dayjs | null; end: Dayjs | null };
  showButton?: boolean;
  enableWarning?: boolean;
  timeSlotsOnDates: TimeSlot[];
};

const AvailabilityDetails: React.FC<Props> = ({
  selectedDates,
  timeSlotsOnDates,
  enableWarning,
  showButton,
}) => {
  const [earliestTime, setEarliestTime] = useState<Dayjs>();
  const [latestTime, setLatestTime] = useState<Dayjs>();
  const [displayAvailabilityWarning, setDisplayAvailabilityWarning] =
    useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (!timeSlotsOnDates || timeSlotsOnDates.length === 0) {
      return;
    }

    const timeSlotsByDay = groupBy(timeSlotsOnDates, (timeSlot) =>
      dayjs(timeSlot.startsAt).format('YYYY-MM-DD'),
    );

    setDisplayAvailabilityWarning(checkTimeSlotsForWarning(timeSlotsByDay));
    setEarliestTime(dayjs(getEarliestTimeSlot(timeSlotsOnDates).startsAt));
    setLatestTime(dayjs(getLatestTimeSlot(timeSlotsOnDates).endsAt));
  }, [timeSlotsOnDates]);

  return (
    <>
      {timeSlotsOnDates && (
        <>
          {timeSlotsOnDates.length > 0 && earliestTime && latestTime ? (
            <>
              {displayAvailabilityWarning ? (
                <div className="flex flex-row items-center">
                  {enableWarning && (
                    <div className="pr-2">
                      <WarningIcon />
                    </div>
                  )}
                  <div>
                    <div>
                      {t(
                        'components.AvailabilityDetails.availabilityMultipleIntervals',
                      )}
                    </div>
                    <div>
                      {t('common.between')} {earliestTime.format('HH:mm')} -{' '}
                      {latestTime.format('HH:mm')}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col">
                  <div>{t('common.availability')}</div>
                  <div className="font-medium">
                    {earliestTime.format('HH:mm')} -{' '}
                    {latestTime.format('HH:mm')}
                  </div>
                </div>
              )}
              {showButton && (
                <div className="flex flex-col justify-center">
                  <a
                    className="font-medium text-safeLifeDark"
                    onClick={() => {
                      navigate(
                        `/tillganglighet/${selectedDates.start?.format(
                          'YYYY-MM-DD',
                        )}/${selectedDates.end?.format('YYYY-MM-DD')}`,
                        {
                          state: { prevPage: location.pathname },
                        },
                      );
                    }}>
                    {t('components.AvailabilityDetails.change')}
                  </a>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="flex flex-col">
                <div>{t('common.availability')}</div>
                <div className="font-medium">
                  {t('components.AvailabilityDetails.noAdded')}
                </div>
              </div>
              {showButton && (
                <Button
                  type="primary"
                  className="font-medium rounded-md bg-safeLifeMedium border-0"
                  onClick={() => {
                    navigate(
                      `/tillganglighet/${selectedDates.start?.format(
                        'YYYY-MM-DD',
                      )}/${selectedDates.end?.format('YYYY-MM-DD')}`,
                      {
                        state: { prevPage: location.pathname },
                      },
                    );
                  }}>
                  {t('components.AvailabilityDetails.addAvailability')}
                </Button>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default AvailabilityDetails;
