import { ExclamationOutlined } from '@ant-design/icons';
import React from 'react';

type Props = {
  onClick?: () => void;
};

const WarningIcon: React.FC<Props> = (large) => {
  return (
    <ExclamationOutlined
      style={{
        fontSize: '0.75rem',
        paddingTop: '0.125rem',
        color: '#FFFFFF',
        backgroundColor: '#F4A52F',
      }}
      className="h-4 w-4 border-2 rounded-full"
    />
  );
};

export default WarningIcon;
