import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
/* eslint-disable-next-line import/no-unresolved */
import './styles/tailwind.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import TokenContextProvider from './context/token';
import UserContextProvider from './context/user';
import AppBarContextProvider from './context/AppBar';
import AppContainerProvider from './context/AppContainer';
import CalendarContextProvider from './context/Calendar';
import LanguageContextProvider from './context/language';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
});

dayjs.extend(isBetween);
dayjs.extend(isoWeek);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <TokenContextProvider>
      <UserContextProvider value={null}>
        <LanguageContextProvider>
          <AppBarContextProvider value={{ title: 'Safelife' }}>
            <AppContainerProvider currentClasses="">
              <CalendarContextProvider
                currentState={{
                  displayedMonth: dayjs(),
                  selectedDates: {
                    start: dayjs().startOf('day'),
                    end: dayjs().endOf('day'),
                  },
                  selectedCourseInstancesCount: 0,
                }}>
                <Router>
                  <App />
                </Router>
              </CalendarContextProvider>
            </AppContainerProvider>
          </AppBarContextProvider>
        </LanguageContextProvider>
      </UserContextProvider>
    </TokenContextProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
