import React from 'react';
import { ClockCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { InfoCell } from '../views/CourseInstanceDetails';
import CourseInstance, {
  CourseInstanceAttendee,
  CourseInstanceAttendeeStatus,
} from '../types/CourseInstance';
import Passed from '../resources/img/passed.svg';
import Failed from '../resources/img/failed.svg';
import Participated from '../resources/img/participated.svg';
import colors from '../theme/colors';

type CourseInstanceAttendeesInfoCellProps = {
  id: CourseInstance['id'];
  attendeesQuery: UseQueryResult<
    AxiosResponse<CourseInstanceAttendee[]> | undefined
  >;
  endsAt: CourseInstance['endsAt'];
};

const CourseInstanceAttendeesInfoCell: React.FC<
  CourseInstanceAttendeesInfoCellProps
> = ({ id, attendeesQuery, endsAt }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const attendees = attendeesQuery.data?.data;

  const onClick = (): void => {
    navigate(`/kurstillfallen/${id}/attendees`, {
      state: { prevPage: location.pathname },
    });
  };

  const attendeesStatusAccumulatorEmpty = {
    participated: 0,
    passed: 0,
    unknown: 0,
    total: 0,
  };

  const attendeesStatusAccumulation =
    attendees?.reduce((acc, attendee) => {
      switch (attendee.status) {
        case CourseInstanceAttendeeStatus.Passed:
          return {
            ...acc,
            participated: acc.participated + 1,
            passed: acc.passed + 1,
            total: acc.total + 1,
          };

        case CourseInstanceAttendeeStatus.Failed:
          return {
            ...acc,
            participated: acc.participated + 1,
            total: acc.total + 1,
          };

        case CourseInstanceAttendeeStatus.DidNotParticipate:
          return {
            ...acc,
            total: acc.total + 1,
          };

        default:
          return {
            ...acc,
            unknown: acc.unknown + 1,
            total: acc.total + 1,
          };
      }
    }, attendeesStatusAccumulatorEmpty) ?? attendeesStatusAccumulatorEmpty;

  const hasEnded = dayjs().isAfter(dayjs(endsAt));

  const renderAttendeeStats = () => {
    if (attendees === undefined) {
      return null;
    }

    if (hasEnded) {
      return (
        <div className="flex flex-col gap-2">
          <div className="flex flex-row items-center gap-2">
            <img src={Participated} className="w-4" />
            {t('views.CourseInstanceAttendees.participatedAttendees', {
              attendeeCount: attendeesStatusAccumulation.participated,
              totalCount: attendeesStatusAccumulation.total,
            })}
          </div>
          <div className="flex flex-row items-center gap-2">
            <img src={Passed} className="w-4" />
            {t('views.CourseInstanceAttendees.participatedAndPassedAttendees', {
              count: attendeesStatusAccumulation.passed,
            })}
          </div>
          <div className="flex flex-row items-center gap-2">
            <img src={Failed} className="w-4" />
            {t('views.CourseInstanceAttendees.participatedAndFailedAttendees', {
              count:
                attendeesStatusAccumulation.participated -
                attendeesStatusAccumulation.passed,
            })}
          </div>
          {attendeesStatusAccumulation.unknown > 0 && (
            <div className="flex flex-row items-center gap-2">
              <ClockCircleOutlined
                style={{
                  color: colors.warningYellow,
                  fontSize: '15px',
                }}
              />
              {t('views.CourseInstanceAttendees.unknown', {
                count: attendeesStatusAccumulation.unknown,
              })}
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="text-blueGrayDark font-medium pl-2">
        {t('views.CourseInstanceAttendees.attendees')}
        &nbsp;{attendees.length}
      </div>
    );
  };

  const renderAttendees = () => {
    return (
      <div className="mt-1 flex justify-between items-center">
        {!attendeesQuery.isError ? (
          renderAttendeeStats()
        ) : (
          <div className="w-full flex flex-row items-center gap-4">
            <span>
              {t('views.CourseInstanceAttendees.couldNotFetchAttendees')}
            </span>
            <Button onClick={() => attendeesQuery.refetch()}>
              {t('common.tryAgain')}
            </Button>
          </div>
        )}

        {!attendeesQuery.isError && (
          <Button
            type="text"
            shape="circle"
            icon={<RightOutlined />}
            onClick={onClick}
          />
        )}
      </div>
    );
  };

  return (
    <InfoCell title={t('common.attendees')}>
      {attendeesQuery.isFetching ? (
        <Spin className="my-1 w-full" />
      ) : (
        renderAttendees()
      )}
    </InfoCell>
  );
};

export default CourseInstanceAttendeesInfoCell;
