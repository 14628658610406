import { isAxiosError } from 'axios';
import gravatar from 'gravatar';
import i18next from 'i18next';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as API from '../api';
import CourseInstanceLimitPicker from '../components/CourseInstanceLimitPicker';
import GoogleMap from '../components/GoogleMap';
import { useAppBar } from '../context/AppBar';
import { useAppContainer } from '../context/AppContainer';
import { useToken } from '../context/token';
import { useUser } from '../context/user';
import LoadCalendar from '../resources/img/add_calendar.svg';
import { removeCountryFromAddress } from '../utils/address';
import LanguagePicker from '../components/LanguagePicker';
import PresetIntervalPicker, {
  availabilityPresetToTimeInterval,
} from '../components/PresetIntervalPicker';
import Language from '../types/Language';
import { TimeInterval } from '../components/TimeIntervalPicker';
import ProfileSignature from '../components/ProfileSignature';

const Profile: React.FC = () => {
  const [, setToken] = useToken();
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useUser();
  const [courseInstanceLimit, setCourseInstanceLimit] = useState<number>();
  const [calendarLink, setCalendarLink] = useState<string>('');
  const [loadingCalendarLink, setLoadingCalendarLink] = useState(false);
  const [loadingLanguages, setLoadingLanguages] = useState(false);
  const [languages, setLanguages] = useState<Language[]>();
  const [presets, setPresets] = useState<TimeInterval[]>([]);
  const [loadingPresets, setLoadingPresets] = useState(true);
  const [, setAppBarInterface] = useAppBar();
  const [, setCurrentClasses] = useAppContainer();

  useEffect(() => {
    setAppBarInterface({ title: t('common.profile'), showBackButton: false });
  }, [i18next.language]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchCalendarLink();
    fetchPresets();
    setCurrentClasses('bg-blueGrayLight');
    fetchLanguages();
    setCourseInstanceLimit(currentUser?.defaultCourseInstanceLimit);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchUser = async () => {
    try {
      const { data } = await API.getProfile();
      setCurrentUser(data);
    } catch (error) {
      if (
        isAxiosError(error) &&
        error.response &&
        error.response.status === 401
      ) {
        setToken(null);
      }
    }
  };

  const fetchPresets = () => {
    if (!currentUser) return;

    API.getUserAvailabilityPresets(currentUser.id)
      .then(({ data }) =>
        setPresets(
          data
            .sort((a, b) => {
              if (a.startsAt > b.startsAt) return 1;
              if (a.startsAt < b.startsAt) return -1;
              return a.endsAt > b.endsAt ? 1 : -1;
            })
            .map<TimeInterval>(availabilityPresetToTimeInterval),
        ),
      )
      .finally(() => setLoadingPresets(false));
  };

  const fetchLanguages = async () => {
    setLoadingLanguages(true);
    try {
      const { data } = await API.getLanguages();
      setLanguages(data);
    } finally {
      setLoadingLanguages(false);
    }
  };

  const fetchCalendarLink = async () => {
    setLoadingCalendarLink(true);
    try {
      const response = await API.getUserCalendarLink(currentUser!.id);
      setCalendarLink(
        `${process.env.REACT_APP_API_BASE_URL}/m/calendar-${response.data.icsToken}.ics`,
      );
    } finally {
      setLoadingCalendarLink(false);
    }
  };

  const updateCourseInstanceLimit = async (value: number) => {
    if (!currentUser || !courseInstanceLimit) {
      return;
    }

    try {
      await API.updateDefaultCourseInstanceLimit(currentUser.id, {
        defaultCourseInstanceLimit: value,
      });
    } finally {
      fetchUser();
      setCourseInstanceLimit(value);
    }
  };

  const updatePreferredLanguage = async (value: number) => {
    if (!currentUser) return;

    try {
      await API.updatePreferredLanguage(currentUser.id, {
        preferredLanguageId: value,
      });
    } finally {
      window.location.reload();
    }
  };

  type InfoInfoCardProps = {
    title: string;
    children: ReactNode;
    topButton?: ReactNode;
    className?: string;
  };

  const InfoCard: React.FC<InfoInfoCardProps> = ({
    title,
    children,
    topButton,
    className,
  }) => {
    return (
      <div className={`bg-white rounded-lg p-3 mb-4 ${className}`}>
        <div className="text-blueGrayDark text-sm mb-1 flex items-top">
          <span className="grow-default">{title}</span> {topButton}
        </div>
        {children}
      </div>
    );
  };

  return (
    <div className="bg-blueGrayLight p-5 view-max-width">
      <div className="flex justify-between items-center pl-1">
        <img
          className="rounded-full w-20"
          src={gravatar.url(currentUser?.email || '', {
            size: '512',
          })}
        />
        <a
          href={calendarLink}
          download={!loadingCalendarLink}
          className="rounded-full bg-warning w-12 h-12 flex justify-center items-center">
          <img src={LoadCalendar} />
        </a>
      </div>
      <div className="ml-2 mb-4">
        <div className="font-semibold text-lg mt-4">{currentUser?.name}</div>
        <div className="text-blueGrayDark mt-3 text-base break-words">
          {currentUser?.email}
        </div>
        <div className="flex gap-1 mt-1 items-center justify-between mr-2 text-blueGrayDark text-base break-words">
          {currentUser?.phoneNumber || t('views.Profile.noPhoneNumberFound')}
        </div>
      </div>
      <InfoCard title={t('views.Profile.yourLocation')}>
        <div className="mb-1 text-base">
          {currentUser && currentUser.locations.length > 0 && (
            <p>{removeCountryFromAddress(currentUser?.locations[0].address)}</p>
          )}
        </div>
        <div>
          {(currentUser?.locations.length ?? 0 > 0) ? (
            <GoogleMap
              pinLocation={currentUser!.locations[0].geolocation}
              className="w-full"
              width={305}
              height={168}
            />
          ) : (
            <div>{t('views.Profile.noLocationAdded')}</div>
          )}
        </div>
        <div className="flex flex-row mt-2 text-base">
          <span className="text-blueGrayDark mr-1">{`${t(
            'views.Profile.maximumTravelDistance',
          )}:`}</span>
          <span>{currentUser?.maxTravelDistance}</span>
          <span className="ml-1">km</span>
        </div>
      </InfoCard>
      <InfoCard title={t('views.Profile.signature')}>
        <ProfileSignature user={currentUser} fetchUser={fetchUser} />
      </InfoCard>
      <InfoCard title={t('views.Profile.presetAvailabilityIntervals')}>
        <PresetIntervalPicker
          presets={presets}
          setPresets={setPresets}
          loadingPresets={loadingPresets}
        />
      </InfoCard>
      <InfoCard title={t('views.Profile.courseInstancesPerDay')}>
        <CourseInstanceLimitPicker
          courseInstanceLimit={courseInstanceLimit}
          maxCourseInstanceLimit={4}
          setCourseInstanceLimit={updateCourseInstanceLimit}
        />
      </InfoCard>
      <InfoCard title={t('common.language')}>
        <LanguagePicker
          languages={languages}
          updateLanguage={updatePreferredLanguage}
          userLanguage={currentUser?.preferredLanguage}
          loading={loadingLanguages}
        />
      </InfoCard>
      <InfoCard title={t('common.courses')}>
        {currentUser?.courses.map((course, key) => {
          return (
            <div className="text-base mb-2 break-words" key={key}>
              {course.name}
            </div>
          );
        })}
      </InfoCard>
    </div>
  );
};

export default Profile;
