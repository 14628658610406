import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, message, Select } from 'antd';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import * as API from '../api';
import {
  CourseInstanceAttendee,
  CourseInstanceAttendeeStatus,
} from '../types/CourseInstance';

import { CourseInstanceAttendeeStatusBox } from './CourseInstanceAttendeeStatusBox';

type EditCourseInstanceAttendeeModalProps = {
  attendee: CourseInstanceAttendee | undefined;
  courseInstanceId: number;
  isMappedInElsa: boolean;
  onEditAttendees: () => void;
  onCancel: () => void;
};

const EditCourseInstanceAttendeeModal: React.FC<
  EditCourseInstanceAttendeeModalProps
> = ({
  attendee,
  courseInstanceId,
  isMappedInElsa,
  onEditAttendees,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    form.setFieldsValue({
      name: attendee?.name,
      email: attendee?.email,
      status: attendee?.status,
    });
  }, [form, attendee]);

  const onFinish = async (values: API.UpdateCourseInstanceAttendeeParams) => {
    if (!attendee) {
      return;
    }
    setSubmitting(true);
    try {
      await API.updateCourseInstanceAttendees(courseInstanceId, [
        {
          id: attendee.id,
          elsaId: attendee.elsaId,
          name: values.name,
          email: values.email,
          status: values.status,
          comment: values.comment,
        },
      ]);
      onEditAttendees();
      onCancel();
    } catch (error) {
      if (isAxiosError(error) && !error.response?.data.message) {
        message.error(t('errors.somethingWentWrong'));
      }
    } finally {
      setSubmitting(false);
    }
  };

  const submit = () => {
    form.submit();
  };

  return (
    <Modal
      title={t('components.EditCourseInstanceAttendeeModal.title')}
      open={!!attendee}
      onCancel={onCancel}
      onOk={submit}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t('common.close')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={submitting}
          form="updateAttendeeForm"
          htmlType="submit">
          {t('common.save')}
        </Button>,
      ]}>
      {attendee && (
        <Form
          id="updateAttendeeForm"
          layout="vertical"
          form={form}
          onFinish={(values) => onFinish(values)}>
          <Form.Item
            label={t('components.EditCourseInstanceAttendeeModal.name')}
            name="name"
            initialValue={attendee.name}
            rules={[
              {
                required: true,
                message: t(
                  'components.EditCourseInstanceAttendeeModal.fillOutName',
                ),
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            initialValue={attendee.email}
            label={t('common.email')}
            rules={[
              {
                type: 'email',
                message: t(
                  'components.EditCourseInstanceAttendeeModal.invalidEmail',
                ),
              },
            ]}>
            <Input disabled={isMappedInElsa} />
          </Form.Item>
          <Form.Item
            name="status"
            label={t('components.EditCourseInstanceAttendeeModal.status')}>
            <Select allowClear optionLabelProp="children">
              <Select.Option value={undefined}>&nbsp;</Select.Option>
              <Select.Option value={CourseInstanceAttendeeStatus.Passed}>
                <CourseInstanceAttendeeStatusBox
                  status={CourseInstanceAttendeeStatus.Passed}
                />
              </Select.Option>
              <Select.Option value={CourseInstanceAttendeeStatus.Failed}>
                <CourseInstanceAttendeeStatusBox
                  status={CourseInstanceAttendeeStatus.Failed}
                />
              </Select.Option>
              <Select.Option
                value={CourseInstanceAttendeeStatus.DidNotParticipate}>
                <CourseInstanceAttendeeStatusBox
                  status={CourseInstanceAttendeeStatus.DidNotParticipate}
                />
              </Select.Option>
            </Select>
          </Form.Item>
          {!isMappedInElsa && (
            <Form.Item
              label={t('components.EditCourseInstanceAttendeeModal.comment')}
              name="comment"
              initialValue={attendee.comment}>
              <Input />
            </Form.Item>
          )}
        </Form>
      )}
    </Modal>
  );
};

export default EditCourseInstanceAttendeeModal;
