import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import * as API from '../api';
import ShortcutButton from '../components/ShortcutButton';
import { useAppBar } from '../context/AppBar';
import CalendarIcon from '../resources/img/calendar.svg';
import ProfileIcon from '../resources/img/profile.svg';
import { useAppContainer } from '../context/AppContainer';
import SafeLifeSpin from '../components/SafeLifeSpin';
import { useUser } from '../context/user';
import PotentialCourseInstanceCard from '../components/PotentialCourseInstanceCard';
import SuggestedCourseInstanceCount from '../types/SuggestedCourseInstanceCount';
import { useCalendar } from '../context/Calendar';
import { CourseInstanceCardData } from '../types/CourseInstance';
import CourseInstanceCard from '../components/CourseInstanceCard';

const Start: React.FC = () => {
  const [courseInstances, setCourseInstances] = useState<
    CourseInstanceCardData[]
  >([]);
  const [currentMonthCourseInstanceCount, setCurrentMonthCourseInstanceCount] =
    useState<SuggestedCourseInstanceCount>();
  const [upcomingCourseInstanceCount, setUpcomingCourseInstanceCount] =
    useState<SuggestedCourseInstanceCount>();
  const [loadingCourseInstances, setLoadingCourseInstances] = useState(true);
  const [loadingCurrentSuggestions, setLoadingCurrentSuggestions] =
    useState(true);
  const [loadingUpcomingSuggestions, setLoadingUpcomingSuggestions] =
    useState(true);
  const navigate = useNavigate();
  const [, setAppBarInterface] = useAppBar();
  const [, setCurrentClasses] = useAppContainer();
  const [, setCalendarState] = useCalendar();
  const { t } = useTranslation();

  const currentUser = useUser()[0];

  useEffect(() => {
    setAppBarInterface({ title: t('common.start') });
  }, [i18next.language]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCurrentClasses('bg-white');
    fetchCourseInstances();
    fetchSuggestedCourseInstanceCount();
    fetchUpcomingCourseInstanceCount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const suggestionCardClick = (month: Dayjs) => {
    setCalendarState({
      displayedMonth: month,
      selectedDates: { start: null, end: null },
      selectedCourseInstancesCount: 0,
    });
    navigate('/kalender');
  };

  const fetchCourseInstances = async () => {
    if (!currentUser) {
      return;
    }
    setLoadingCourseInstances(true);
    try {
      const today = dayjs().startOf('day');
      await API.getCourseInstances({
        startsAt: today.toISOString(),
        endsAt: today.add(7, 'days').toISOString(),
        instructorId: currentUser.id,
      }).then((response) => {
        if (response.status == 200) {
          setCourseInstances(response.data.data);
        }
      });
    } finally {
      setLoadingCourseInstances(false);
    }
  };

  const fetchSuggestedCourseInstanceCount = async () => {
    if (!currentUser || currentUser.id === null) {
      return;
    }
    setLoadingCurrentSuggestions(true);
    try {
      const today = dayjs().startOf('day');
      const { data } = await API.getSuggestedCourseInstanceCount({
        instructorId: currentUser.id,
        startDate: today.format('YYYY-MM-DD'),
        endDate: today.endOf('month').format('YYYY-MM-DD'),
      });
      setCurrentMonthCourseInstanceCount(data);
    } finally {
      setLoadingCurrentSuggestions(false);
    }
  };

  const fetchUpcomingCourseInstanceCount = async () => {
    if (!currentUser || currentUser.id === null) {
      return;
    }
    setLoadingUpcomingSuggestions(true);
    try {
      const today = dayjs().startOf('day');
      const { data } = await API.getSuggestedCourseInstanceCount({
        instructorId: currentUser.id,
        startDate: today.add(1, 'month').startOf('month').format('YYYY-MM-DD'),
        endDate: today.add(3, 'months').endOf('month').format('YYYY-MM-DD'),
      });
      setUpcomingCourseInstanceCount(data);
    } finally {
      setLoadingUpcomingSuggestions(false);
    }
  };

  return (
    <div className="p-4 view-max-width">
      <div className="flex justify-between gap-1 px-1 py-1">
        <ShortcutButton
          text={t('common.calendar')}
          onClick={() => navigate('/kalender')}
          icon={<img src={CalendarIcon} />}
        />
        <div className="w-4"></div>
        <ShortcutButton
          text={t('common.profile')}
          onClick={() => navigate('/profil')}
          icon={<img src={ProfileIcon} className="pt-1" />}
        />
      </div>
      <div className="flex mt-4 justify-center flex-col">
        {(currentMonthCourseInstanceCount?.matchedCourseInstanceCount !== 0 ||
          currentMonthCourseInstanceCount?.potentialCourseInstanceCount !==
            0) && (
          <PotentialCourseInstanceCard
            start={dayjs()}
            end={dayjs().endOf('month')}
            matchedCount={
              currentMonthCourseInstanceCount?.matchedCourseInstanceCount
            }
            potentialCount={
              currentMonthCourseInstanceCount?.potentialCourseInstanceCount
            }
            loading={loadingCurrentSuggestions}
            onClick={() => suggestionCardClick(dayjs())}
          />
        )}
        {(upcomingCourseInstanceCount?.matchedCourseInstanceCount !== 0 ||
          upcomingCourseInstanceCount?.potentialCourseInstanceCount !== 0) && (
          <PotentialCourseInstanceCard
            start={dayjs().add(1, 'month')}
            end={dayjs().add(3, 'month')}
            matchedCount={
              upcomingCourseInstanceCount?.matchedCourseInstanceCount
            }
            potentialCount={
              upcomingCourseInstanceCount?.potentialCourseInstanceCount
            }
            loading={loadingUpcomingSuggestions}
            onClick={() => suggestionCardClick(dayjs().add(1, 'month'))}
            className="mt-4"
          />
        )}
      </div>
      <div className="text-2xl font-semibold mt-6 mb-2">
        {t('common.courseInstances')}
      </div>
      <div className="text-blueGrayMediumDark mb-1">
        {t('views.Start.confirmedSevenDays')}
      </div>
      <div className="border-solid border-0 border-t-1 border-grayLight mb-3" />
      <div>
        {loadingCourseInstances ? (
          <div className="flex justify-center items-center mt-14">
            <SafeLifeSpin size="large" />
          </div>
        ) : courseInstances.length == 0 ? (
          <div className="text-blueGrayMediumDark font-normal text-sm py-4 rounded-lg bg-white flex-1 flex flex-col items-center shadow-md border-solid border-1 border-grayLight">
            {t('views.Start.noConfirmedSevenDays')}
          </div>
        ) : (
          courseInstances.map((courseInstance, key) => {
            return (
              <CourseInstanceCard
                key={key}
                courseInstance={courseInstance}
                onAccept={() => fetchCourseInstances()}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default Start;
