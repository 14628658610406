import { Collapse } from 'antd';
import React, { useEffect, useState } from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import MobileCalendarDate from '../types/MobileCalendarDate';
import { CourseInstanceCardData } from '../types/CourseInstance';
import { CourseInstanceType } from '../types/CourseInstanceType';

import CourseInstanceCard from './CourseInstanceCard';

const { Panel } = Collapse;

type CollapseProps = {
  className?: string;
  calendarDates: MobileCalendarDate[];
  onAccept: () => void;
};

const CourseInstanceCollapse: React.FC<CollapseProps> = ({
  className,
  calendarDates,
  onAccept,
}) => {
  const [confirmedCourseInstances, setConfirmedCourseInstances] = useState<
    CourseInstanceCardData[]
  >([]);
  const [potentialCourseInstances, setPotentialCourseInstances] = useState<
    CourseInstanceCardData[]
  >([]);
  const [matchedCourseInstances, setMatchedCourseInstances] = useState<
    CourseInstanceCardData[]
  >([]);

  useEffect(() => {
    setConfirmedCourseInstances(
      calendarDates.flatMap((calendarDate) => calendarDate.courseInstances),
    );
    setPotentialCourseInstances(
      calendarDates.flatMap(
        (calendarDate) => calendarDate.potentialCourseInstances,
      ),
    );
    setMatchedCourseInstances(
      calendarDates.flatMap(
        (calendarDate) => calendarDate.matchedCourseInstances,
      ),
    );
  }, [calendarDates]);
  const { t } = useTranslation();

  return (
    <Collapse
      className={`bg-blueGrayLight ${className}`}
      bordered={false}
      expandIconPosition={'end'}
      expandIcon={({ isActive }) =>
        isActive ? <MinusOutlined /> : <PlusOutlined />
      }
      defaultActiveKey={1}>
      {confirmedCourseInstances.length > 0 && (
        <Panel
          header={`${t(
            'components.CourseInstanceCollapse.confirmedCourseInstances',
          )}: ${confirmedCourseInstances.length}`}
          key="1">
          {confirmedCourseInstances.map((courseInstance, key) => {
            return (
              <CourseInstanceCard
                key={key}
                courseInstance={courseInstance}
                onAccept={onAccept}
                courseInstanceType={CourseInstanceType.Assigned}
              />
            );
          })}
        </Panel>
      )}
      {matchedCourseInstances.length > 0 && (
        <Panel
          header={`${t(
            'components.CourseInstanceCollapse.matchedCourseInstances',
          )} ${matchedCourseInstances.length}`}
          key="2">
          {matchedCourseInstances.map((courseInstance, key) => {
            return (
              <CourseInstanceCard
                key={key}
                courseInstance={courseInstance}
                onAccept={onAccept}
                courseInstanceType={CourseInstanceType.Matched}
              />
            );
          })}
        </Panel>
      )}
      {potentialCourseInstances.length > 0 && (
        <Panel
          header={`${t(
            'components.CourseInstanceCollapse.potentialCourseInstances',
          )} ${potentialCourseInstances.length}`}
          key="3">
          {potentialCourseInstances.map((courseInstance, key) => {
            return (
              <CourseInstanceCard
                key={key}
                courseInstance={courseInstance}
                onAccept={onAccept}
                courseInstanceType={CourseInstanceType.Potential}
              />
            );
          })}
        </Panel>
      )}
    </Collapse>
  );
};

export default CourseInstanceCollapse;
