import React, { FC } from 'react';
import { Button, Modal, QRCode } from 'antd';
import { useTranslation } from 'react-i18next';
import { CopyOutlined } from '@ant-design/icons';

type QRCodeModalProps = {
  title: string;
  url: string;
  close: () => void;
};

export const QRCodeModal: FC<QRCodeModalProps> = ({ title, url, close }) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={title}
      open
      onCancel={close}
      footer={[
        <Button key="back" onClick={close}>
          {t('common.close')}
        </Button>,
      ]}
      centered>
      <span>{t('components.QRCodeModal.information')}</span>

      <div className="mt-4 flex flex-col items-center gap-4">
        <QRCode value={url} size={250} />

        <Button
          icon={<CopyOutlined />}
          onClick={() => {
            if (url) {
              void navigator.clipboard.writeText(url);
            }
          }}>
          {t('views.CourseInstanceDetails.copyLink')}
        </Button>
      </div>
    </Modal>
  );
};
