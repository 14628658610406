import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { Picker } from 'antd-mobile';
import { PickerColumn, PickerValue } from 'antd-mobile/es/components/picker';
import { DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import Language from '../types/Language';

import SafeLifeSpin from './SafeLifeSpin';

type LanguagePickerProps = {
  className?: string;
  languages?: Language[];
  loading?: boolean;
  userLanguage?: Language;
  updateLanguage: (value: number) => void;
};

const LanguagePicker: React.FC<LanguagePickerProps> = ({
  className,
  languages,
  loading,
  userLanguage,
  updateLanguage,
}) => {
  const [editing, setEditing] = useState(false);
  const [pickerColumns, setPickerColumns] = useState<PickerColumn[]>();
  const { t } = useTranslation();

  useEffect(() => {
    if (languages) {
      setPickerColumns([
        languages.map((language) => {
          return {
            label: t(`languages.${language.code}`),
            value: language.id.toString(),
          };
        }),
      ]);
    }
  }, [languages]); // eslint-disable-line react-hooks/exhaustive-deps

  const onConfirm = (values: PickerValue[]) => {
    if (!editing || !values[0]) {
      return;
    }
    try {
      const newLanguage = parseInt(String(values[0]));
      updateLanguage(newLanguage);
    } finally {
      setEditing(false);
    }
  };

  return (
    <div className={`${className}`}>
      {!loading ? (
        <>
          <Button
            className={`w-full font-semibold h-10`}
            onClick={() => setEditing(true)}>
            <div className="flex w-full grow-default items-center align-center justify-between text-sm	font-medium">
              {userLanguage
                ? t(`languages.${userLanguage.code}`)
                : t('components.LanguagePicker.selectLanguage')}
              <DownOutlined />
            </div>
          </Button>

          {pickerColumns && (
            <Picker
              columns={pickerColumns}
              confirmText={t('common.choose')}
              cancelText={t('common.cancel')}
              visible={editing}
              title={t('components.LanguagePicker.selectLanguage')}
              value={[`${userLanguage?.id}`]}
              onConfirm={(value) => onConfirm(value)}
              onCancel={() => setEditing(false)}
            />
          )}
        </>
      ) : (
        <div className="flex justify-center">
          <SafeLifeSpin />
        </div>
      )}
    </div>
  );
};

export default LanguagePicker;
