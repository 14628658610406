import React from 'react';
import { Spin } from 'antd';

import SpinIcon from '../resources/img/safe_life_spin.svg';

type SpinSize = 'small' | 'default' | 'large';

type Props = {
  size?: SpinSize;
  className?: string;
};

const Spinner = <img src={SpinIcon} className="animate-spin" />;

const SafeLifeSpin: React.FC<Props> = ({ size, className }) => {
  return <Spin indicator={Spinner} size={size} className={className} />;
};

export default SafeLifeSpin;
