import React, { useEffect, useState } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { capitalize } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  CourseInstanceCardData,
  CourseInstanceStatus,
} from '../types/CourseInstance';
import { removeCountryFromAddress } from '../utils/address';
import { CourseInstanceType } from '../types/CourseInstanceType';
import { courseInstanceIsAcceptable } from '../utils/courseInstance';

import AcceptCourseInstanceModal from './AcceptCourseInstanceModal';

type StatusInfo = {
  text: string;
  textStyle: string;
  courseInstanceDotStyle: string;
};

const cardStatusBadge = (statusInfo?: StatusInfo) => {
  return (
    <div className="flex flex-row items-center">
      <div
        className={`w-3 h-3 mr-1 rounded-default ${statusInfo?.courseInstanceDotStyle}`}
      />
      <div
        className={`font-semibold items-center ${statusInfo?.textStyle}`}
        style={{ marginBottom: '-1px' }}>
        {statusInfo?.text}
      </div>
    </div>
  );
};

type CourseInstanceCardProps = {
  courseInstance: CourseInstanceCardData;
  courseInstanceType?: CourseInstanceType;
  onAccept: () => void;
};

const CourseInstanceCard: React.FC<CourseInstanceCardProps> = ({
  courseInstanceType = CourseInstanceType.Assigned,
  courseInstance,
  onAccept,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [statusInfo, setStatusInfo] = useState<StatusInfo>();
  const startDate = dayjs(courseInstance.startsAt);
  const endDate = dayjs(courseInstance.endsAt);
  const navigate = useNavigate();
  const location = useLocation();

  const onModalSuccess = () => {
    setShowModal(false);
    onAccept();
  };

  const handleClick = (): void => {
    navigate(`/kurstillfallen/${courseInstance.id}`, {
      state: { prevPage: location.pathname },
    });
  };
  const { t } = useTranslation();

  useEffect(() => {
    if (courseInstanceType === CourseInstanceType.Assigned) {
      switch (courseInstance.status) {
        case CourseInstanceStatus.Confirmed: {
          setStatusInfo({
            text: dayjs(courseInstance.endsAt).isAfter(dayjs())
              ? t('common.confirmed')
              : t('components.CourseInstanceCard.completed'),
            textStyle: 'text-success',
            courseInstanceDotStyle: 'confirmed-dot',
          });
          break;
        }
        case CourseInstanceStatus.Preliminary: {
          setStatusInfo({
            text: t('components.CourseInstanceCard.preliminary'),
            textStyle: 'text-warningYellow',
            courseInstanceDotStyle: 'preliminary-dot',
          });
          break;
        }
        case CourseInstanceStatus.Canceled: {
          setStatusInfo({
            text: t('components.CourseInstanceCard.canceled'),
            textStyle: 'text-red-800',
            courseInstanceDotStyle: 'cancelled-dot',
          });
          break;
        }
      }
    } else {
      switch (courseInstanceType) {
        case CourseInstanceType.Matched: {
          setStatusInfo({
            text: t('common.matched'),
            textStyle: 'text-blueGrayMediumDark',
            courseInstanceDotStyle: 'matched-dot',
          });
          break;
        }
        case CourseInstanceType.Potential: {
          setStatusInfo({
            text: t('components.CourseInstanceCard.potentialCourseInstance'),
            textStyle: 'text-blueGrayMediumDark',
            courseInstanceDotStyle: 'potential-dot',
          });
          break;
        }
      }
    }
  }, [courseInstance, courseInstanceType, t]);

  const date = capitalize(startDate.format('dddd  D MMMM'));
  const time = `${startDate.format('HH:mm')} ${t(
    'components.CourseInstanceCard.until',
  )} ${endDate.format('HH:mm')}`;
  return (
    <div className="mb-4 rounded-lg flex flex-col cursor-pointer shadow-md bg-white border-solid border-grayLight border-1">
      <div className="flex flex-row p-2" onClick={handleClick}>
        <div className="flex-1 px-1">
          <div className={`flex gap-1 mb-2}`}>
            {cardStatusBadge(statusInfo)}
          </div>
          <div className="text-blueGrayDark">
            {courseInstance.courseName}
            {' • '}
            {courseInstance.subsidiaryCompanyName}
          </div>
          <div className="text-lg font-semibold mt-2 mb-1 break-words max-w-full">
            {courseInstance.clientName}
          </div>
          <div>
            <span>
              {date} {t('components.CourseInstanceCard.at')} {time}
            </span>
          </div>

          <div>{removeCountryFromAddress(courseInstance.address)}</div>
        </div>
        <RightOutlined className="self-center text-lg" />
      </div>
      {courseInstanceIsAcceptable(
        courseInstance.instructorId,
        courseInstance.startsAt,
      ) && (
        <div className="border-0 border-t-1 border-gray-300 border-solid flex grow-default">
          <Button
            className="m-3 w-full bg-safeLifeDark text-white border-0"
            onClick={() => setShowModal(true)}>
            {t('components.CourseInstanceCard.acceptCourseInstance')}
          </Button>
          {showModal && (
            <AcceptCourseInstanceModal
              courseInstanceId={courseInstance.id}
              startsAt={courseInstance.startsAt}
              endsAt={courseInstance.endsAt}
              clientName={courseInstance.clientName}
              address={courseInstance.address}
              open={showModal}
              onCancel={() => setShowModal(false)}
              onSuccess={onModalSuccess}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CourseInstanceCard;
