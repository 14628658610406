const gray = {
  100: '#f7fafc',
  200: '#edf2f7',
  300: '#e2e8f0',
  400: '#cbd5e0',
  500: '#a0aec0',
  600: '#718096',
  700: '#4a5568',
  800: '#2d3748',
  900: '#1a202c',
};

const red = {
  100: '#fff5f5',
  200: '#fed7d7',
  300: '#feb2b2',
  400: '#fc8181',
  500: '#f56565',
  600: '#e53e3e',
  700: '#c53030',
  800: '#9b2c2c',
  900: '#742a2a',
};

const orange = {
  100: '#fffaf0',
  200: '#feebc8',
  300: '#fbd38d',
  400: '#f6ad55',
  500: '#ed8936',
  600: '#dd6b20',
  700: '#c05621',
  800: '#9c4221',
  900: '#7b341e',
};

const yellow = {
  100: '#fffff0',
  200: '#fefcbf',
  300: '#faf089',
  400: '#f6e05e',
  500: '#ecc94b',
  600: '#d69e2e',
  700: '#b7791f',
  800: '#975a16',
  900: '#744210',
};

const green = {
  100: '#f0fff4',
  200: '#c6f6d5',
  300: '#9ae6b4',
  400: '#68d391',
  500: '#48bb78',
  600: '#38a169',
  700: '#2f855a',
  800: '#276749',
  900: '#22543d',
};

const blue = {
  100: '#ebf8ff',
  200: '#bee3f8',
  300: '#90cdf4',
  400: '#63b3ed',
  500: '#4299e1',
  600: '#3182ce',
  700: '#2b6cb0',
  800: '#2c5282',
  900: '#2a4365',
};

const colors = {
  transparent: 'transparent',
  current: 'currentColor',

  textDefault: 'rgba(26, 32, 44, 0.85)',

  black: '#000',
  white: '#fff',

  gray,
  red,
  orange,
  yellow,
  green,
  blue,

  primary: blue,
  accent: orange,

  calendar: '#F8FBFD',
  past: '#C4C4C426',
  safeLifeLight: '#5883C2',
  safeLifeMedium: '#2A5C96',
  safeLifeDark: '#25487D',
  safeLifeDarker: '#2f3549',
  safeLifeRed: '#EC5B56',
  blueGrayLightest: '#F8FBFD',
  blueGrayLight: '#EAF3FA',
  blueGrayMedium: '#D8E4F4',
  blueGrayMediumDark: '#75889F',
  blueGrayDark: '#545B6F',
  grayLight: '#E3E5E9',
  grayMedium: '#7F7F7F',
  grayDark: '#505050',
  warning: '#D14951',
  warningYellow: '#F4A52F',
  warningBG: '#FEF6EA',
  warningLight: '#FFF0F0',
  success: '#4EAA7A',
  successLight: '#EBFAF3',
};

module.exports = colors;
