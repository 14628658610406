import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { capitalize } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import * as API from '../api';
import { useUser } from '../context/user';
import AvailabilityDetails from '../components/AvailabilityDetails';
import { useAppBar } from '../context/AppBar';
import { useAppContainer } from '../context/AppContainer';
import { useCalendar } from '../context/Calendar';
import MobileCalendar from '../components/MobileCalendar';
import { getTimeSlotsBetweenDates } from '../utils/instructorCalendar';
import CalendarFilterSelector, {
  CalendarFilter,
} from '../components/CalendarFilterSelector';
import MobileCalendarDate from '../types/MobileCalendarDate';
import CourseInstanceCollapse from '../components/CourseInstanceCollapse';
import SafeLifeSpin from '../components/SafeLifeSpin';

const CourseInstanceCalendar: React.FC = () => {
  const [calendarDates, setCalendarDates] = useState<MobileCalendarDate[]>([]);
  const [selectedCalendarDates, setSelectedCalendarDates] = useState<
    MobileCalendarDate[]
  >([]);
  const [loadingCalendar, setLoadingCalendar] = useState(false);
  const [calendarFilter, setCalendarFilter] = useState<CalendarFilter>({
    availability: true,
    matches: true,
    potentialCourseInstances: true,
  });
  const [calendarState, setCalendarState] = useCalendar();
  const [, setAppBarInterface] = useAppBar();
  const [, setCurrentClasses] = useAppContainer();
  const currentUser = useUser()[0];
  const { t } = useTranslation();

  useEffect(() => {
    setAppBarInterface({
      title: t('common.calendar'),
      showBackButton: false,
    });
  }, [i18next.language]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCurrentClasses('bg-blueGrayLight');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (calendarState.selectedDates.start === null) {
      const startDate = calendarState.displayedMonth
        .startOf('month')
        .startOf('day');
      const endDate = calendarState.displayedMonth.endOf('month').endOf('day');
      setSelectedCalendarDates(
        calendarDates.filter((calendarDate) =>
          dayjs(calendarDate.date).isBetween(startDate, endDate),
        ),
      );
    } else {
      setSelectedCalendarDates(
        calendarDates.filter((calendarDate) =>
          dayjs(calendarDate.date).isBetween(
            calendarState.selectedDates.start,
            calendarState.selectedDates.end,
            'days',
            '[]',
          ),
        ),
      );
    }
  }, [
    calendarState.selectedDates.start,
    calendarState.selectedDates.end,
    calendarState.displayedMonth,
    calendarDates,
  ]);

  useEffect(() => {
    if (!calendarState.displayedMonth) {
      return;
    }

    fetchCalendar();
  }, [calendarState.displayedMonth]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCalendar = async () => {
    if (!currentUser || currentUser.id === null) {
      return;
    }

    const firstDate = calendarState.displayedMonth
      .startOf('month')
      .startOf('week')
      .startOf('day');
    const lastDate = firstDate.add(6, 'weeks').subtract(1, 'day').endOf('day');

    setLoadingCalendar(true);
    try {
      const { data } = await API.getMobileCalendar({
        instructorId: currentUser.id,
        startDate: firstDate.format('YYYY-MM-DD'),
        endDate: lastDate.format('YYYY-MM-DD'),
      });
      setCalendarDates(data);
    } finally {
      setLoadingCalendar(false);
    }
  };

  const changeDisplayedMonth = (
    amount: number,
    _start?: Dayjs,
    _end?: Dayjs,
  ) => {
    setCalendarState((prevState) => {
      return {
        displayedMonth: prevState?.displayedMonth.add(amount, 'M'),
        selectedDates: { start: _start ?? null, end: _end ?? null },
        selectedCourseInstancesCount: 0,
      };
    });
  };

  const singleSelectedDay = () => {
    return calendarState.selectedDates.start?.isSame(
      calendarState.selectedDates.end,
      'day',
    );
  };

  const getCalendarHeader = () => {
    return (
      <div className="flex justify-between p-4">
        <Button
          onClick={() => changeDisplayedMonth(-1)}
          icon={<LeftOutlined />}
        />
        <div className="font-medium text-lg pt-1">
          {capitalize(calendarState.displayedMonth?.format('MMMM YYYY'))}
        </div>
        <Button
          onClick={() => changeDisplayedMonth(1)}
          icon={<RightOutlined />}
        />
      </div>
    );
  };

  const getSelectedDateText = () => {
    if (calendarState.selectedDates.start === null) {
      const timeSlotCount = getTimeSlotsBetweenDates(
        calendarDates,
        calendarState.displayedMonth.startOf('month').startOf('day'),
        calendarState.displayedMonth.endOf('month').endOf('day'),
      ).length;

      return (
        <div className="flex flex-row items-center">
          <div className="text-base text-black font-medium">
            {capitalize(calendarState.displayedMonth.format('MMMM'))}
          </div>
          <div className="text-base px-2 font-medium">•</div>
          <div className="text-base	text-gray-700 font-normal">
            {timeSlotCount > 0 ? (
              <>
                {t('common.days', { count: timeSlotCount })}&nbsp;
                {t('views.CourseInstanceCalendar.enteredAvailability')}
              </>
            ) : (
              <>{t('views.CourseInstanceCalendar.noEnteredAvailability')}</>
            )}
          </div>
        </div>
      );
    }

    if (singleSelectedDay()) {
      return (
        <div className="text-base text-black font-medium">
          {capitalize(calendarState.selectedDates.start?.format('dddd D'))}{' '}
          {calendarState.selectedDates.start?.format('MMMM')}
        </div>
      );
    } else {
      return (
        <div className="text-base text-black font-medium">
          {calendarState.selectedDates.start?.format('D')} -{' '}
          {calendarState.selectedDates.end?.format('D')}{' '}
          {calendarState.selectedDates.start?.format('MMMM')}
        </div>
      );
    }
  };

  return (
    <div>
      <div className="bg-blueGrayLight view-max-width">
        <CalendarFilterSelector
          className="border-0 border-b-1 border-solid border-blueGrayMedium"
          filter={calendarFilter}
          setFilter={setCalendarFilter}
          calendarDates={calendarDates.filter((calendarDate) =>
            dayjs(calendarDate.date).isBetween(
              calendarState.displayedMonth.startOf('month').startOf('day'),
              calendarState.displayedMonth.endOf('month').endOf('day'),
              null,
              '[]',
            ),
          )}
        />
        <MobileCalendar
          filter={calendarFilter}
          loading={loadingCalendar}
          calendarDates={calendarDates}
          getCalendarHeader={getCalendarHeader}
        />
        <div className="flex flex-col h-full mx-4 mt-2">
          <div className="pt-2 bg-blueGrayLight flex flex-col grow-default">
            {loadingCalendar ? (
              <SafeLifeSpin size="small" className="py-5" />
            ) : (
              <>
                <div className="mb-1">{getSelectedDateText()}</div>
                {calendarState.selectedDates.start &&
                  calendarState.selectedDates.end && (
                    <>
                      <div className="flex flex-row justify-between mt-3">
                        <AvailabilityDetails
                          showButton={true}
                          selectedDates={calendarState.selectedDates}
                          timeSlotsOnDates={getTimeSlotsBetweenDates(
                            calendarDates,
                            calendarState.selectedDates.start,
                            calendarState.selectedDates.end,
                          )}
                        />
                      </div>
                    </>
                  )}
                <CourseInstanceCollapse
                  className="pt-1"
                  calendarDates={selectedCalendarDates}
                  onAccept={() => fetchCalendar()}
                />
              </>
            )}
            <div className="py-3 pl-1 text-blueGrayDark">
              {t('views.CourseInstanceCalendar.useCalendarAvailability')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseInstanceCalendar;
