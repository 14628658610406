import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationSv from './sv/translation.json';
import translationEn from './en/translation.json';

export const resources = {
  sv: {
    translation: translationSv,
  },
  en: {
    translation: translationEn,
  },
} as const;

use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: { order: ['navigator'] },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  });
