import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';

import { Suggestion } from '../components/AddressSearchInput';

export const getSuggestionByPlaceId = async (
  placeId: string,
): Promise<Suggestion> => {
  const [result] = await geocodeByPlaceId(placeId);
  const latLng = await getLatLng(result);

  return {
    address: result.formatted_address,
    geolocation: latLng,
    placeId: result.place_id,
  };
};

export const getSuggestionByAddress = async (
  address: string,
): Promise<Suggestion> => {
  const [result] = await geocodeByAddress(address);
  const latLng = await getLatLng(result);

  return {
    address: result.formatted_address,
    geolocation: latLng,
    placeId: result.place_id,
  };
};

/*
  Example:

  const address = 'Kungsgatan 53, 111 22 Stockholm, Sweden';
  const formatedAddress = removeCountryFromAddress(address);

  console.log(address);
  >>> Kungsgatan 53, 111 22 Stockholm, Sweden
  console.log(formatedAddress);
  >>> Kungsgatan 53, 111 22 Stockholm
*/

export const removeCountryFromAddress = (address: string | undefined) => {
  return address ? address.split(', ').slice(0, -1).join(', ') : '-';
};
